import * as React from "react";
import {render} from "react-dom";
import {Grid, Typography, IconButton} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import TimerIcon from '@material-ui/icons/Timer';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    text: {
        color: "#000000"
    }
  });


export default function TimerWidget(props) {
    
    const [counter, setCounter] = React.useState(props.time);

    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if(props.roundInProgress && props.storeTime!=null && props.storeTime!=undefined) props.storeTime(counter);
    }, [counter]);

    /*React.useEffect(()=>{
        return function cleanup() {
            console.log("[TimerWidget] cleanup");
            // TO DO
            setCounter(null);
        };
    })*/   

    if(counter <= 0 && counter!=null){
        console.log("[TimerWidget] time elapsed, should call timeElapsed on gameView? " + props.roundInProgress);
        setCounter(null);
        if(props.roundInProgress && props.timeElapsed!=null && props.timeElapsed!=undefined) props.timeElapsed();
    };

    const classes = useStyles();
    
    /*
        <Grid container spacing={2}   alignItems="center">
            <Grid item xs={4}>
                <IconButton aria-label="edit" disableRipple disableFocusRipple={true} disabled>
                <TimerIcon colour="secondary" fontSize="medium"/>
                    </IconButton>
                
            </Grid>
            <Grid item xs={4}>
                <Typography className={classes.text} component="h5" variant="h5" align="right">{counter}</Typography>
            </Grid>
        </Grid>
    */
    return (
        <Typography className={classes.text} component="h5" variant="h5" align="right">{counter}</Typography>
        
    );
}
