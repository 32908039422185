import React, {Component} from "react";
import * as firebaseMgr from "./utils/FirebaseMgr";
import * as userMgr from "./utils/UserManager";
import ErrorSnackbar from "./components/ErrorSnackbar";

import {Redirect} from 'react-router';

class Lobby extends Component {

    constructor(props) {
        super(props);
        this.state = {
            playerList: [],
            playerCount: 1,
            userListenerRegistered: false,
            name: this.props.name,
            errorMsg : null,
            showErrorSnackbar : false
        };

        this.playerListItems = null;
        this.updatePlayerList = this.updatePlayerList.bind(this);
        this.playerDisconnected = this.playerDisconnected.bind(this);
        //this.initialiseFbListener = this.initialiseFbListener.bind(this);
    }

    componentDidMount(){

        //this.onNewPlayerJoined(this.state.name);
        // Get current player list
        let playerListInitial = userMgr.getPlayerNames();

        // Update player list
        this.updatePlayerList(playerListInitial);
        // for(var i=0; i<playerListInitial.length; i++){
        //     this.onNewPlayerJoined(playerListInitial[i]);
        // }
    }


    //onNewPlayersJoined(players) {
    updatePlayerList(players){
        console.log("[Lobby] players: " + JSON.stringify(players));
        // reset the player list and redraw it
        
        this.setState({playerList : players, playerCount: players.length})
        if(players.length>0){
            this.playerListItems = players.map((player) =>
                <li>{player}</li>
            );
        }    
        
        
        /*console.log("new player: " + playerName);
        let tempPlayerListArr = this.state.playerList;
        tempPlayerListArr.push(playerName);
        console.log(tempPlayerListArr);
        this.setState({playerList: tempPlayerListArr});

        this.state.playerCount++;
        this.playerListItems = tempPlayerListArr.map((player) =>
            <li>{player}</li>
        );*/
    }

    playerDisconnected(name){
        console.log("[Lobby] playerDisconnected: " + name);

        let errorText = name + " has disconnected";
        this.setState({
            showErrorSnackbar: true,
            errorMsg : errorText
        })
    }

    closeErrorMsg(){
        this.setState({
            showErrorSnackbar : false,
            errorMsg : null
        })
    }


    render() {  
        const {playerCount, showErrorSnackbar} = this.state;
 
        if(!this.state.userListenerRegistered){
            console.log("will attempt to register user listneer")
            // attempt to register user listener
            this.state.userListenerRegistered = userMgr.registerUserListChangeListener(this);

        }     
        return (
            <div>
            {showErrorSnackbar && <ErrorSnackbar errorMsg={this.state.errorMsg} close={this.closeErrorMsg}></ErrorSnackbar>}
                <h2>Players ({this.state.playerCount})</h2>
                {playerCount>0 && <ul>{this.playerListItems}
                </ul>}
                {playerCount==0 && <ul>{this.state.name}
                </ul>}
            </div>
        );
    }
}

export default Lobby;