import * as fbMgr from "./FirebaseMgr";

var database = fbMgr.database;
let sequencesArr = null;
let sequenceNum = 1;

export function retrievePreviousGame(gameId) {
    return new Promise(function (resolve, reject) {
        console.log("[PreviousResultsHandler] retrievePreviousGame called, gameId: " + gameId);
        // get ref to db/games/gameId
        fbMgr.getPrevGameRef(gameId).then(prevGameRef => {

            // get ref to sequences
            let sequencesRef = prevGameRef.child("sequences");

            console.log("[PreviousResultsHandler] sequencesRef : " + sequencesRef);
            return sequencesRef;

        }).then(sequencesRef => {

            // read sequences
            sequencesRef.once("value").then(function (data) {
                let seqObj = data.val();

                console.log("[PreviousResultsHandler] sequencesRef = " + sequencesRef + " data = " + JSON.stringify(data));
                console.log("[PreviousResultsHandler] sequencesRef = " + " seqObj = " + JSON.stringify(seqObj));

                if (seqObj != null) {
                    if(seqObj[0] == null){
                        // remove null value at start of sequence
                        console.log("[PreviousResultsHandler] removing null from seqObj: " + JSON.stringify(seqObj));
                        seqObj.shift();
                    }
                    
                    console.log("[PreviousResultsHandler] seqObj no null, resolving with: " + JSON.stringify(seqObj));
                    
                    sequencesArr = seqObj;
                    // resolve with the first sequence
                    resolve(seqObj[0]);
                } else {
                    reject("unable to get sequences");
                }

            })
        }).catch(error => {
            console.error("[PreviousResultsHanlder] retrievePreviousGame failed, error: " + error);
            reject("retrievePreviousGame failed, error: " + error);
        })
    })
}

export function isLastSeq(num){
    if(num >= sequencesArr.length) return true;
    else return false;
}

export function getSequence(num){
    if(sequencesArr !== null && sequencesArr[num-1] != null && sequencesArr[num-1] != undefined){
        return sequencesArr[num-1];
    }
}