import React, {Component, useEffect , useState} from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

let hasOpened = false;

export default function SimpleSnackbar(props) {

    const classes = useStyles();

    console.log("[ErrorSnackbar] initialised with props: " + JSON.stringify(props));

    const [open,
        setOpen] = React.useState(false);
    
    const msg = props.errorMsg;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            props.close();
            return;
        }

        props.close();
        setOpen(false);
    };

    useEffect(() => {
        handleClickOpen()
        // The cleanup function is effectively the same as unmount
        return function cleanup() {};
    });

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
                open={open}
                autoHideDuration={4800}
                onClose={handleClose}
                message={msg}
                action={< React.Fragment > 
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}>
                    <CloseIcon fontSize="small"/>
                </IconButton> 
                </React.Fragment>}><Alert severity="error">{msg}</Alert></Snackbar>
        </div>
    );
}
