import React, {Component} from "react";
import * as gameGenerator from './utils/GameGenerator';
import * as userMgr from './utils/UserManager';
import Lobby from "./Lobby";
import { Redirect } from 'react-router';
import * as gameMgr from "./utils/GameManager";
import * as fbMgr from "./utils/FirebaseMgr";
import {Button, Grid, Container, Typography, SnackbarContent} from '@material-ui/core';
import CopyURL from "./components/CopyURL";
import CopyURLSnackbar from "./components/CopyURLSnackbar";
import * as clipboard from "clipboard-polyfill";
import ErrorSnackbar from "./components/ErrorSnackbar";


class PreGameView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.location.state.name,
            gameId: this.props.location.state.gameId,
            hasGameId: this.props.location.state.hasGameId,
            nameWithoutHostTag: this.props.location.state.nameWithoutHostTag,
            startGame:false,
            hostStarted:false,
            isHost: this.props.location.state.host,
            userId: null,
            showCopyUrlMessage : false,
            errorMsg : null,
            showErrorSnackbar : false
        };

        this.startGame = this.startGame.bind(this);
        this.copyUrl = this.copyUrl.bind(this);
        this.closePopup = this.closePopup.bind(this);

        fbMgr.logToFbAnalytics("pregameview - host: " + this.state.isHost);

        // code to prompt user for confirmation on closing tab
        window.addEventListener('beforeunload', function (e) { 
            e.preventDefault(); 
            e.returnValue = "Are you sure you want to close the game? You will not be able to rejoin.";
        }); 
    }

    startGame(event) {
        this.setState({startGame:true})
        event.preventDefault();
    }

    hostStarted(){
        this.setState({hostStarted:true});
    }

    copyUrl(){
        let _this = this;
        let url = "https://sillysketch.com/?gameId=" + this.state.gameId;
        clipboard.writeText(url).then(
            function() { 
                /* clipboard successfully set */
                _this.setState({
                    showCopyUrlMessage : true
                })
                console.log("[PreGameView] success copying to clipboard"); 
            },
            function() { 
                console.log("[PreGameView] error copying to clipboard"); 
                _this.setState({
                    showErrorSnackbar : true,
                    errorMsg : "Unable to copy to clipboard, please copy the URL manually."
                })
            }
        );
    }


    closePopup(){
        this.setState({
            showCopyUrlMessage : false,
            showErrorSnackbar : false,
            errorMsg : null
        })
    }


    // When initialising the component, we need to check if we already have a game ID; if not then get a new one
    async componentDidMount(){

        if(!this.state.hasGameId && this.state.isHost){
            const gameGenResponse = await gameGenerator.generateGame();
            this.setState({gameId : gameGenResponse, hasGameId:true});
        

            const loginResponse = await userMgr.loginToGame(this.state.name, this.state.gameId, this.state.isHost);
                if(loginResponse!= false && loginResponse != -1 && loginResponse != -2){
                    this.setState({userId: loginResponse});

                    userMgr.registerUserListener();
                }
                else
                    console.log("[HostView] Error logging user in");
        }else{
            // if not the host, we need to listen for changes to user and an event when host starts the game
            userMgr.registerUserListener();
            
            gameMgr.registerHostStartListener(this,this.state.gameId);
        }
    }

    render() {

        if(this.state.startGame || this.state.hostStarted){
            console.log("[PreGameView] startGame: " + this.state.startGame + " hostStarted: " + this.state.hostStarted);
            return <Redirect push to={{pathname:"/GameView",state:{name:this.state.name, gameId: this.state.gameId, isHost:this.state.isHost}}}/>
        }

        const {isHost, hasGameId, userId, showCopyUrlMessage, showErrorSnackbar} = this.state;

        return (
            <div>
            {showErrorSnackbar && <ErrorSnackbar errorMsg={this.state.errorMsg} close={this.closePopup}></ErrorSnackbar>}
            {showCopyUrlMessage && <CopyURLSnackbar close={this.closePopup}></CopyURLSnackbar>}
            {isHost && <div>
                <h1>Hi {this.state.name}!</h1>
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography component="h5" variant="h5">Share this URL with your friends: https://sillysketch.com/?gameId={this.state.gameId}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={this.copyUrl.bind(this)}>Copy URL</Button>
                    </Grid>
                </Grid>
                <h2></h2>
                
                <br></br>
                <p>Once everyone has joined and you're ready to start, click "Begin" below.</p>
                <br/>
                {hasGameId && <Lobby name={this.state.name}></Lobby>}

                {hasGameId && <Button variant="contained" color="primary" onClick={this.startGame.bind(this)}>Begin</Button>}
            </div>}

            {!isHost &&
                            <div>
                                
                <h1>Hi {this.state.name}, waiting for the host to start the game.</h1>
                <br/>
                <Typography component="h5" variant="h5">Game code: {this.state.gameId}</Typography>
                <br/>
            <Lobby name={this.state.name}></Lobby>
            </div>}

            </div>
        );
    }
}

export default PreGameView;