import React, {Component} from "react";
import Typography from '@material-ui/core/Typography';



class CaptionResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : this.props.data,
            name : this.props.name
        };

    }

    componentDidUpdate(prevProps){
        if(prevProps.data !== this.props.data || prevProps.name !== this.props.name){
            this.setState({data : this.props.data,  name : this.props.name});
        }
    }

    render() {
        return (
            <div>
                <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>{this.state.name} wrote: <Typography component="h4" variant="h4" align="left" color="textPrimary" >"{this.state.data}"</Typography></Typography>
            </div>
        );
    }
}

export default CaptionResult;