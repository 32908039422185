import React, {Component} from "react";
import {Typography, Button, Grid, Container, Card} from "@material-ui/core";
import {Redirect} from 'react-router';
import CopyURLSnackbar from "./CopyURLSnackbar";
import * as clipboard from "clipboard-polyfill";
import ErrorSnackbar from "./ErrorSnackbar";


class PreviousGamesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gameList: this.props.gameList,
            showCopyUrlMessage: false,
            errorMsg : null,
            showErrorSnackbar : false
        }

        this.onLinkClick = this
            .onLinkClick
            .bind(this);
        this.copyURL = this
            .copyURL
            .bind(this);
        this.closePopup = this
            .closePopup
            .bind(this);
        console.log("[PreviousGamesList] init, props:" + JSON.stringify(props) + " gameList: " + JSON.stringify(this.state.gameList));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.gameList !== this.props.gameList) {
            let gameListArr = Object.entries(this.props.gameList);
            this.setState({gameList: gameListArr});
        }
    }

    onLinkClick(gameId) {
        console.log("[PreviousGamesList] onLinkClick, gameId: " + gameId)
        let urlStr = "https://sillysketch.com/?results=" + gameId;
        window.location.href = urlStr;
    }

    copyURL(gameId) {
        console.log("[PreviousGamesList] copyUrl, gameId: " + gameId)
        let url = "https://sillysketch.com/?results=" + gameId;

        let _this = this;
        clipboard.writeText(url).then(
            function() { 
                /* clipboard successfully set */
                _this.setState({
                    showCopyUrlMessage : true
                })

                console.log("[PreviousGamesList] success copying to clipboard"); 
            },
            function() { 
                console.log("[PreviousGamesList] error copying to clipboard"); 
                _this.setState({
                    showErrorSnackbar : true,
                    errorMsg : "Unable to copy to clipboard."
                })
            }
          );

    }

    closePopup() {
        this.setState({
            showCopyUrlMessage: false,
            showErrorSnackbar : false,
            errorMsg : null
        })
    }

    //            <li key={Object.keys(d)}>{Object.values(d)}</li>

    render() {

        const {showCopyUrlMessage, showErrorSnackbar} = this.state;

        // build the list of items
        const listItems = this
            .state
            .gameList
            .map((d) => <div>
                <Container maxWidth="sm">
                    <Grid alignContent="center" container justify="center">
                        <Grid item xs={9} container justify="center">
                            <Button
                                style={{
                                justifyContent: 'center'
                            }}
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                this.onLinkClick(Object.keys(d))
                            }}
                                key={Object.keys(d)}>{Object.values(d)}</Button>
                        </Grid>
                        <Grid item xs={3} container justify="center">
                            <Button
                                style={{
                                justifyContent: 'center'
                            }}
                                size="large"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                this.copyURL(Object.keys(d))
                            }}
                                key={Object.keys(d)}>Copy</Button>
                        </Grid>
                    </Grid>
                </Container>
                <br></br>
            </div>);
        console.log(listItems);

        return (

            <div>
                <ul>{listItems}</ul>
                {showErrorSnackbar && <ErrorSnackbar errorMsg={this.state.errorMsg}></ErrorSnackbar>}
                {showCopyUrlMessage && <CopyURLSnackbar close={this.closePopup}></CopyURLSnackbar>}
            </div>
        );
    }
}

export default PreviousGamesList;