import React, {Component} from "react";
import {Redirect} from 'react-router';
import * as fbMgr from "./utils/FirebaseMgr";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
import TimerWidget from "./components/TimerWidget";
import DrawingView from "./DrawingView";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
//import ContactForm from "./components/ContactForm";
import ColourMenu from "./components/ColourMenu";
import GameHeader from "./components/GameHeader";
import * as copyHandler from "./utils/CopyHandler";
import * as clipboard from "clipboard-polyfill";


class Home extends Component {

    MyButton = styled(Button)({
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px'
    });

    constructor(props) {
        super(props);
        this.state = {
            create: false,
            join: false,
            name: null,
            resultsFromUrl: false,
            joinFromUrl: false,
            gameId: null
        };

        this.handleChange = this
            .handleChange
            .bind(this);
        this.joinGame = this
            .joinGame
            .bind(this);
        this.createGame = this
            .createGame
            .bind(this);

        // on launch, check whether a game ID was included in the URL
        this.processQueryString = this
            .processQueryString
            .bind(this);

        console.log("[Home] props: " + JSON.stringify(this.props));

        //this.props.test(120,false);

        

    }

    handleChange(event) {
        this.setState({name: event.target.value});
    }

    createGame(event) {
        if (this.state.name != null) {
            var name = this.state.name + " (host)";
            fbMgr.logToFbAnalytics("creategame");
            this.setState({create: true, join: false, name: name});
        } else 
            alert("Please enter your name");
        event.preventDefault();
    }

    joinGame(event) {
        this.setState({join: true, create: false})
        event.preventDefault();
    }

    componentDidMount() {
        fbMgr.initialiseFirebaseMgr();
        this.processQueryString(this.props.location.search);
    }

    processQueryString(queryString) {

        console.log("[Home] processQueryString: " + queryString);
        const urlParams = new URLSearchParams(queryString);
        const gameId = urlParams.get("gameId");
        const resultsId = urlParams.get("results");
        const endgame = urlParams.get("endgame");
        console.log("[Home] processQueryString, gameId: " + gameId + " results: " + resultsId + " endgame: " + endgame);
        if (gameId != null && gameId != undefined) {
            this.setState({joinFromUrl: true, gameId: gameId});
        } else if (resultsId != null && resultsId != undefined) {
            this.setState({resultsFromUrl: true, gameId: resultsId});
        } else if (endgame !== null && endgame !== undefined) {
            console.log("[Home] endgame true, refreshing page");

            this
                .props
                .history
                .push("/");
            window
                .location
                .reload();
        } else {
            return null;
        }
    }

    render() {
        // when either create or join is pressed, the relevant state is set. At this
        // point we want to redirect to host view or join view
        if (this.state.create) {
            return <Redirect
                push
                to={{
                pathname: "/PreGameView",
                state: {
                    name: this.state.name,
                    host: true,
                    gameId: '',
                    hasGameId: false
                }
            }}/>
        } else if (this.state.join) {
            return <Redirect
                push
                to={{
                pathname: "/Join",
                state: {
                    name: this.state.name
                }
            }}/>
        } else if (this.state.gameId && this.state.joinFromUrl) {
            return <Redirect
                push
                to={{
                pathname: "/JoinFromUrl",
                state: {
                    gameId: this.state.gameId
                }
            }}/>
        } else if (this.state.gameId && this.state.resultsFromUrl) {
            return <Redirect
                push
                to={{
                pathname: "/ResultsFromUrl",
                state: {
                    gameId: this.state.gameId
                }
            }}/>
        }
        return(
            <div>
                {/* {<GameHeader time={120} isFirstRound={true} caption={"This is a rather long caption with lots of words"}></GameHeader>} */}
                <Container maxWidth="sm">
                    <br></br>
                    <Typography
                        variant="body"
                        align="left"
                        color="textPrimary"
                        gutterBottom="true"
                        paragraph>
                        Consequences is an old parlour game for 2-10 players. It&apos;s really simple to
                        play:
                        <br></br>
                        1. Everyone gets a prompt has 30 seconds to write something for that prompt, e.g. a person's name, a place, something they do
                        <br></br>
                        2. Once everyone has submitted an answer, they are shown the next prompt to write for
                            <br></br>
                        3. This repeats until players have submitted an answer for every part of a story. The game then pieces together everyone's submissions, and generates a series of stories that players can read through.
                        <br></br>
                        <br></br>
                        Please enter your name below then click "Create Game".
                    </Typography>

                    <Container maxWidth="xl">

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <form noValidate>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        name="name"
                                        autoComplete="name"
                                        multiline
                                        rowsMax={1}
                                        value={this.state.name}
                                        onChange={this.handleChange}/>
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth="true"
                                    onClick={this
                                    .createGame
                                    .bind(this)}>Create Game</Button>
                            </Grid>
                        </Grid>

                    </Container>

                    {/*<DrawingView
                        round={1}
                        sequence={1}
                        submit={false}
                        gameId={"abc"}
                        captionStr={"hello"}
                        gameViewRef={this}
                isFirstRound={true}/>*/}

                    {/*<ColourSelector></ColourSelector>*/}

                </Container>
            </div>

        );
    }
}

export default Home;