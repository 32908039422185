import React, {Component} from "react";
import DeviceDetector from "device-detector-js";
import Button from "@material-ui/core/Button";
import CopyURLSnackbar from "./components/CopyURLSnackbar";
import * as clipboard from "clipboard-polyfill";
import ErrorSnackbar from "./components/ErrorSnackbar";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formWidth: 400,
            userAgent: undefined,
            executionTime: undefined,
            device: undefined,
            showCopyMessage : false,
            errorMsg : null,
            showErrorSnackbar : false
        }

        this.parseUserAgent = this
            .parseUserAgent
            .bind(this);
        this.copyInfo = this
            .copyInfo
            .bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.deviceDetector = new DeviceDetector();
    }

    componentDidMount() {
        let width = Math.round(window.innerWidth * 0.8);
        this.setState({formWidth: width})

        if (window.location.hash) {
            const userAgent = decodeURIComponent(window.location.hash.substr(1));

            return this.parseUserAgent(userAgent);
        }

        this.parseUserAgent(navigator.userAgent);
    }

    parseUserAgent = userAgent => {
        if (userAgent.trim() === (this.state.userAgent || "").trim()) {
            return this.setState({userAgent});
        }

        const device = this.deviceDetector.parse(userAgent);

        this.setState({userAgent, device});
    };

    copyInfo() {
        let _this = this;
        clipboard.writeText(JSON.stringify(this.state.device)).then(
            function() { 
                /* clipboard successfully set */
                _this.setState({
                    showCopyUrlMessage : true
                })
                console.log("[Contact] success copying to clipboard"); 
            },
            function() { 
                console.log("[Contact] error copying to clipboard"); 
                _this.setState({
                    showErrorSnackbar : true,
                    errorMsg : "Unable to copy to clipboard."
                })
            }
            );
    }

    closePopup() {
        this.setState({
            showCopyMessage: false,
            showErrorSnackbar : false,
            errorMsg : null
        })
    }

    render() {
        const {showCopyMessage, showErrorSnackbar} = this.state;
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={() => this.copyInfo()}>Copy useful information to clipboard</Button>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLScXbMuAt_mkNX7Q1Ki1Pg7jy_SYRr2QKqtw9SytZQ9fK753Dg/viewform?embedded=true"
                    width={this.state.formWidth}
                    height="1500"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0">Loading…</iframe>
            
            {showCopyMessage && <CopyURLSnackbar close={this.closePopup}></CopyURLSnackbar>}
            {showErrorSnackbar && <ErrorSnackbar errorMsg={this.state.errorMsg} close={this.closePopup}></ErrorSnackbar>}
            </div>
        );
    }
}

export default Contact;