

import * as React from "react";
import {render} from "react-dom";
import {AppBar, Button, Grid, IconButton, Typography, Toolbar} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import TimerWidget from "./TimerWidget";
import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      position: "fixed",
      width: "100%"
    },
    gameheader: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        position: "fixed",
        paddingBottom : 10,
        width: "100%"
      },
    toolbar: {
        background: "#ffffff",
        padding: "5px",
        elevation: "1"
    },
    text: {
        color: "#000000"
    },
    captionText:{
        fontSize : 18,
        whiteSpace: 'pre-line'
    },
    smallText:{
        fontSize : 13,
        whiteSpace: 'pre-line'
    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
      }
  });

export default function GameHeader(props) {

    //console.log("[GameHeader] props: " + JSON.stringify(props));

    // React.useEffect(() => {
        
    //     console.log("[TimerWidget] counter updated " + counter);
    // }, [counter]);

    //const editCaption;

    const classes = useStyles();
    
    return (
        <div>
            {/* <Typography component="h2" variant="h2" className={classes.root}>Test sticky area</Typography> */}
        {/* <AppBar position="sticky" className={classes.toolbar} maxWidth={false}>*/
            <Toolbar classes={classes.gameheader}>
                <Grid container spacing={3}   alignItems="center">
                    {!props.isCaption && <>
                        <Grid item xs={7} md={8} justify="left">
                            <Typography className={classes.text} component="h6" variant="h6" className={classes.captionText} gutterBottom paddingBottom={2}>Draw "{props.caption}"</Typography>
                        </Grid>
                        {props.isFirstRound && 
                            <><Grid item xs={3} md={2} justify="center">
                                <IconButton aria-label="edit" label="edit" classes={{label: classes.iconButtonLabel}} onClick={()=>props.editPromptCb()}>
                                    <EditIcon fontSize="medium"/>
                                    <Typography component="p" variant="p" className={classes.smallText}>Edit <br></br>Prompt</Typography>    
                                </IconButton>
                            
                            </Grid> </>}
                        
                        <Grid item xs={2} md={2} container justify="right">
                            <TimerWidget time={props.time} timeElapsed={props.timeElapsedCb} storeTime={props.storeTimeCb} roundInProgress={props.roundInProgress}></TimerWidget>
                        </Grid>
                    </>}

                    {props.isCaption && <>
                        <Grid item xs={10} justify="left">
                            <Typography className={classes.text} component="h6" variant="h6" className={classes.captionText} gutterBottom paddingBottom={2}>What do you think this is?</Typography>
                        </Grid>
                        
                        <Grid item xs={1} container justify="right">
                            <TimerWidget time={props.time} timeElapsed={props.timeElapsedCb} storeTime={props.storeTimeCb} roundInProgress={props.roundInProgress}></TimerWidget>
                        </Grid>
                    </>}
                    
                </Grid>
            </Toolbar>
        /*</AppBar> */}
        </div>
    );
}




