import React, {Component} from "react";
import { useHistory } from "react-router-dom";
import CaptionView from "./CaptionView";
import ResultsView from "./ResultsView";
import NewPrompt from "./components/NewPrompt";
import * as userMgr from "./utils/UserManager";
import * as gameMgr from "./utils/GameManager";
import * as fbMgr from "./utils/FirebaseMgr";
import { createBrowserHistory } from 'history';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TimerWidget from "./components/TimerWidget";
import GameHeader from "./components/GameHeader";
import * as clipboard from "clipboard-polyfill";
import ErrorSnackbar from "./components/ErrorSnackbar";

import {Redirect} from 'react-router';


// const styles = theme =>({
//     TimerWidget:{
//         position: "fixed"
//     }
// })

const timerBarStyle = {
    position: "fixed"
}
//const history = useHistory();

class GameView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.location.state.name,
            gameId: this.props.location.state.gameId,
            isWaiting: false,
            isCaption: false,
            isFinished: false,
            newPromptEntry : false,
            captionStr: "",
            submitted:false,
            isHost: this.props.location.state.isHost,
            currentSequence : null,
            currentRound : 0,
            isFirstRound : true,
            errorMsg : null,
            showErrorSnackbar : false, 
            showLoadingAnimation : true
        };
        this.drawingViewRef = React.createRef();
        this.captionViewRef = React.createRef();
        
        this.submit = this.submit.bind(this);
        this.updateGameState = this.updateGameState.bind(this);
        this.onGameStateUpdate = this.onGameStateUpdate.bind(this); 
        this.updatePrompt = this.updatePrompt.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.timeElapsed = this.timeElapsed.bind(this);
        this.beforeUnloadFunction = this.beforeUnloadFunction.bind(this);
        this.endGame = this.endGame.bind(this);
        this.storeTime = this.storeTime.bind(this);
        this.playerDisconnected = this.playerDisconnected.bind(this);
        
        // code to prompt user for confirmation on closing tab
        window.addEventListener('beforeunload', this.beforeUnloadFunction); 

        
    }

    beforeUnloadFunction = function(e){
        console.log("[GameView] window unload event listener,  e = " + JSON.stringify(e));
        if(e!==null && e!=undefined){
            e.preventDefault(); 
            e.returnValue = "Are you sure you want to close the game? You will not be able to rejoin.";
        }
        
    }

    componentDidMount(){

        
        // push "home" onto the browser history so we don't go back to the pregame screen
        const history = createBrowserHistory();
        // Register a simple prompt message that will be shown to the
        // user before they navigate away from the current page.
        const unblock = history.block(this.state.name + ", are you sure you want to leave this game? You will not be able to rejoin!");


        // set game state to drawing
        //this.updateGameState("drawing");

        gameMgr.initialiseGameManager(this.state.isHost);

        gameMgr.registerGameView(this);

        userMgr.registerUserListener()

        userMgr.registerUserListChangeListener(this, true);
    }

    

    async submit() {
        // first set state to waiting
        this.setState({isWaiting : true, submitted: true, newPromptEntry:false});
        if(this.state.isCaption){
            console.log("[GameView] about to submit caption");
            // get caption from the caption view
            this.captionViewRef.current.getCaption().then((caption)=>{
                if(caption != false && caption != undefined){
                    console.log("[GameView] caption received from caption view = " + caption);
                    gameMgr.writeData(caption, this.state.name);
                    userMgr.setSubmissionState(true);
                    this.setState({isCaption : false, isWaiting: true});
                }
                // else{
                //     console.error("[GameView] caption retrieval failure, caption view response = " + caption);
                // }
            }).catch(error =>{
                console.error("[GameView] caption retrieval failure (catch statement), error: " + error);
            })
        }
        
    }

    updatePrompt(prompt){
        console.log("[GameView] update prompt: " + prompt)
        fbMgr.logToFbAnalytics("custompromptentered");
        this.setState({
            captionStr : prompt,
        });

        gameMgr.updatePromptInDb(prompt);
    }

    showPromptEntry(){
        this.setState({
            newPromptEntry : true
        })
    }

    closePopup(){
        this.setState({
            newPromptEntry : false,
            showErrorSnackbar : false,
            errorMsg : null
        })
    }
    
    updateGameState(state){
        gameMgr.setGameState(state);
    }

    timeElapsed(){
        console.log("[GameView] time elapsed invoked");
        this.submit();
    }

    storeTime(time){
        //console.log("[GameView] store time invoked: " + time);
        this.setState({
            remainingTime : time,
            stillDisplayTime : true
        })
    }

    onGameStateUpdate(roundObj){    
        console.log("[GameView] onGameStateUpdate, obj: " + JSON.stringify(roundObj));
        this.setState({captionStr: roundObj.captionStr})
        console.log("[GameView] caption = " + this.state.captionStr);

        if(roundObj.roundNum > 1){
            this.setState({isFirstRound : false});
            console.log("[GameView] setting isFirstRound: false");
        }

        let dataStillLoading = true;
        if(roundObj.captionStr=="" && roundObj.roundNum==1){
            dataStillLoading = true;
        }else{
            dataStillLoading = false;
        }

        this.setState({imageUrl: roundObj.image,
            isCaption : roundObj.isCaption, 
            captionStr: this.state.captionStr, 
            currentRound : roundObj.roundNum, 
            currentSequence : roundObj.currentSequence,
            isWaiting : roundObj.isWaiting,
            isFinished : roundObj.isFinished,
            showLoadingAnimation : dataStillLoading
        });

        if(this.state.isFinished){
            console.log("[GameView] removing event listener");
            window.removeEventListener('beforeunload', this.beforeUnloadFunction);
        }
    
    }

    playerDisconnected(name){
        console.log("[GameView] playerDisconnected: " + name);

        let errorText = name + " has disconnected";
        this.setState({
            showErrorSnackbar: true,
            errorMsg : errorText
        })
    }

    endGame(){
        window.removeEventListener('beforeunload', this.beforeUnloadFunction);
        //window.location.reload();
        //window.location.href = "https://sillysketch.com";
        //window.location.href = "localhost:3000/";
        this.props.history.push("/?endgame=true");
    }

    render() {
        const {isCaption, isWaiting, isFinished, currentSequence, currentRound, newPromptEntry, stillDisplayTime, remainingTime, showErrorSnackbar, showLoadingAnimation} = this.state;
        return (
            <div>

                {isFinished && <ResultsView isCurrentGame={true} gameId={this.state.gameId} endGame={this.endGame} isHost={this.state.isHost}/>}

                {showLoadingAnimation && 
                <>
                    <br></br>
                    <Grid container justify="center" direction="column" alignItems="center" spacing={3}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                        <Grid item>
                            <Typography component="p" variant="p">Loading game...</Typography>
                        </Grid>
                    </Grid>
                </>}
                
                {showErrorSnackbar && <ErrorSnackbar errorMsg={this.state.errorMsg} close={this.closePopup}></ErrorSnackbar>}

                <div>
                
                <Container maxWidth="md">
                    {/* <Grid alignContent="center" container justify="center"> */}
                        
                                    
                {newPromptEntry && !showLoadingAnimation && !isWaiting && !isCaption && <NewPrompt gvRef={this} updatePrompt={this.updatePrompt} close={this.closePopup}/>}
                    

                    {isCaption && <>
                        {/* <Grid item xs={12} container justify="center">
                        <TimerWidget time={60} timeElapsed={this.timeElapsed} storeTime={this.storeTime} roundInProgress={true}></TimerWidget>
                        </Grid> */}
                        <Grid item xs={12} container justify="center">
                        <CaptionView  ref={this.captionViewRef} prompt={this.state.prompt} name={this.state.name} gameViewRef={this}/>
                        </Grid>
                    </>}

                    {isWaiting && <>
                        <Typography component="h5" variant="h5" align="center" color="textPrimary" gutterBottom>
                            The next round will start when the other players have submitted their responses, or in {this.state.remainingTime} seconds
                        </Typography>
                        </>}
                    
                    {!isWaiting && isCaption &&
                        <> 
                            <div></div>
                            <br></br>
                            <Grid item xs={12} justify="center">
                                <Button variant="contained" color="primary" fullWidth={true}  onClick={this.submit.bind(this)}>Submit</Button>
                            </Grid>
                        </>}

                </Container>
                </div>

            </div>
        );
    }
}



export default GameView;