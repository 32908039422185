import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ClearIcon from '@material-ui/icons/Clear';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        backgroundColor : "#fafafa"
    }
})((props) => (<Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center'
}}
    transformOrigin={{
    vertical: 'top',
    horizontal: 'center'
}}
    {...props}/>));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem);

export default function ColourMenu(props) {
    const [anchorEl,
        setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelection = (event) => {
        let colour = event.currentTarget.dataset.colour;
        console.log("[ColourMenu] selected: " + colour);
        props.changeColour(colour);
        handleClose();
    }

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}>
                Select Colour
            </Button>

            {/* Add grey and purple */}
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <StyledMenuItem onClick={handleSelection} data-colour={"black"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#000000"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Black"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"blue"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#0400ff"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Blue"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"brown"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#7d3800"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Brown"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"green"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#44fc30"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Green"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"grey"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#dbdbdb"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Grey"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"orange"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#ff6200"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Orange"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"pink"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#ff85ca"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Pink"/>
                </StyledMenuItem>
                
                <StyledMenuItem onClick={handleSelection} data-colour={"purple"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#bf37ed"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Purple"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"red"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#ff0000"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Red"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"white"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#ffffff"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="White"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"yellow"}>
                    <ListItemIcon>
                        <FiberManualRecordIcon
                            fontSize="medium"
                            style={{
                            fill: "#f5f242"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Yellow"/>
                </StyledMenuItem>

                <StyledMenuItem onClick={handleSelection} data-colour={"eraser"}>
                    <ListItemIcon>
                        <ClearIcon
                            fontSize="medium"
                            style={{
                            fill: "#ff0000"
                        }}/>
                    </ListItemIcon>
                    <ListItemText primary="Eraser"/>
                </StyledMenuItem>

            </StyledMenu>
        </div>
    );
}
