import * as fbMgr from "./FirebaseMgr";

var database = fbMgr.database;

let gameId = '';
var usersRef, gameRef;
let userFirebaseId;


const gameInitObj = {
    users: "",
    gameData : {
        state: "waitForPlayers",
        round: -1,
        serverShouldProcess : false
    }
}

export async function generateGame() {
    var ref = database.ref("games");

    console.log(ref);

    var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 2; i++) {
        gameId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    const now = new Date();
    let timestamp = Math.round(now.getTime() / 1000).toString();
    gameId+="_"
    gameId+=timestamp;

    console.log("[GameGenerator] gameId with timestamp: " + gameId);

    if (ref != null) {
        console.log("About to add gameId to database");

        // create an entry for this game in the database, and populate it with an empty users list
        ref.child(gameId).set(gameInitObj);

        // save a reference to the game object and users object
        gameRef = ref.child(gameId);
        usersRef = gameRef.child("users");

        console.log("Storing refs: " + JSON.stringify(gameRef) + " " + JSON.stringify(usersRef))
        fbMgr.storeRefs(gameRef,usersRef);

        
    }
    return gameId;
}

export function getGameId(){
    return gameId;
};


/* Part of improvements to try and handle people joining midway through
export async function joinGame(gameId){
    let _this = this;
    return new Promise(function(resolve,reject){
        var ref = database.ref("games");
        _this.gameRef = ref.child(gameId);
        fbMgr.storeGameRef(gameRef);
        resolve(_this.gameRef);
    })
    
}*/

// REVERTED TO THIS FROM V0.8.3
export async function joinGame(gameId){
    var ref = database.ref("games");
    gameRef = ref.child(gameId);
    fbMgr.storeGameRef(gameRef);
    return gameRef;
}