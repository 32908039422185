import React, {Component} from "react";
import { Redirect } from 'react-router';
import * as userMgr from './utils/UserManager';
import Button from '@material-ui/core/Button';



class JoinFromUrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            gameId: this.props.location.state.gameId,
            moveToLobby: false,
            unableToJoinActiveGame: false,
            otherJoinError : false
        };

        this.handleChange = this
            .handleChange
            .bind(this);
        this.joinGame = this
            .joinGame
            .bind(this);
    }

    handleChange(event) {
        this.setState({name: event.target.value});
    }

    /*
        TRYING TO STOP PEOPLE JOINING MIDWAY THROUGH A GAME
    async joinGame(event){
        event.preventDefault();
        if(this.state.name==null || this.state.name==""){
            alert("Please enter your name");
        }
        else{
            await userMgr.loginToGame(this.state.name, this.state.gameId, false).then(loginResponse=>{
                console.log("[JoinFromUrl] Login response: " + loginResponse);
                if(loginResponse!= false && loginResponse!= undefined && loginResponse != null && loginResponse != -1 && loginResponse != -2)
                    this.setState({userFirebaseId: loginResponse, moveToLobby:true});
                else if(loginResponse == -1){
                    this.setState({unableToJoinActiveGame : true});
                }else{
                    this.setState({otherJoinError : true});
                    console.log("[Join.js] Error logging user in");
                }
            }).catch(error=>{
                console.log("[JoinFromUrl] Error logging user in: " + error);
                if(error == -1){
                    this.setState({unableToJoinActiveGame : true});
                }else{
                    this.setState({otherJoinError : true});     
                }
            });
        
        }
    }*/

    // REVERTED TO THIS FROM V0.8.3
    async joinGame(event){
        event.preventDefault();
        if(this.state.name==null || this.state.name==""){
            alert("Please enter your name");
        }
        else{
            const canJoinGame = await userMgr.canJoinGame(this.state.gameId).catch(error=>{
                console.error("[JoinFromUrl] Will not be able to attempt to join game, error: " + error);
                if(error == -1){
                    console.error("[JoinFromUrl] Game full, redirecting to full game error screen");
                    this.setState({unableToJoinActiveGame : true});
                }else{
                    console.error("[JoinFromUrl] Game doesn't exist or is no longer active, redirecting to generic error screen");
                    this.setState({otherJoinError : true});  
                }

            });
            if(canJoinGame == true){
                const loginResponse = await userMgr.loginToGame(this.state.name, this.state.gameId, false);
                console.log("[Join] Login response: " + loginResponse);
                if(loginResponse!= false)
                    this.setState({userFirebaseId: loginResponse, moveToLobby:true});
                else
                    console.error("[Join.js] Error logging user in");
            }else if(canJoinGame == -1){
                console.error("[JoinFromUrl] Will not be able to attempt to join game, error: " + canJoinGame);
                this.setState({unableToJoinActiveGame : true});
            }else{
                console.error("[JoinFromUrl] Will not be able to attempt to join game, error: " + canJoinGame);
                this.setState({otherJoinError : true});  
            }
            
            
        }
    }



    render() {
        if(this.state.moveToLobby){
            return <Redirect push to={{pathname:"/PreGameView",state:{name:this.state.name, gameId: this.state.gameId, host:false}}}/>
        }

        if(this.state.unableToJoinActiveGame){
            return <Redirect push to={{pathname:"/ErrorPage", state:{errorMessage : "Unable to join game because it is already in progress."}}}/>
        }

        if(this.state.otherJoinError){
            return <Redirect push to={{pathname:"/ErrorPage", state:{errorMessage : "Sorry, unable to join the requested game. Please check that the host sent the right link, and that they are currently waiting for players to join."}}}/>
        }

        return (
            <div>
                <h2>Joining game "{this.state.gameId}"</h2>
                <form onSubmit={this.joinGame}>
                    <label>
                        Player Name:
                        <input type="text" value={this.state.name} onChange={this.handleChange}/>
                    </label>
                    <Button variant="contained" color="primary" type="submit"> Join Game </Button>
                </form>
            </div>
        );
    }
}

export default JoinFromUrl;