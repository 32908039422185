import React, {Component} from "react";
import {Redirect} from 'react-router';
import * as gameMgr from "./utils/GameManager";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { retrievePreviousGame } from "./utils/PreviousResultsHandler";

class RejoinLoading extends Component {
    constructor(props) {
        super(props);
        console.log("[RejoinLoading] props: " + JSON.stringify(this.props.location.state));
        this.state = {
            prevGameId: this.props.location.state.prevGameId,
            name: this.props.location.state.name,
            playerId: this.props.location.state.prevPlayerId,
            rejoinSuccess : false
        };

        gameMgr.rejoin(this.state.prevGameId, this.state.playerId, this);
        this.moveToGameView = this.moveToGameView.bind(this);
    }

    moveToGameView(retrievedName){

        console.log("[RejoinLoading] moveToGameView, name: " + retrievedName);
        this.setState({
            "name" : retrievedName,
            "rejoinSuccess" : true
        })
    }

    render() {
        
        if(this.state.rejoinSuccess){
            console.log("[RejoinLoading] moving to game view, name: "+ this.state.name + " gameId: " + this.state.prevGameId);
            return <Redirect push to={{pathname:"/GameView",state:{name:this.state.name, gameId: this.state.prevGameId, isHost:false}}}/>
        }

        return (
            <div>
                    <Typography component="h4"
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    gutterBottom>Hi {this.state.name}</Typography>

                    <Typography
                    component="h5"
                    variant="h5"
                    align="center"
                    color="textPrimary"
                    gutterBottom>We're reconnecting you to game {this.state.prevGameId}  :) </Typography>

            </div>
        );
    }
}

export default RejoinLoading;