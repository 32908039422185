import React, {Component} from "react";
import { Redirect } from 'react-router';
import * as userMgr from './utils/UserManager';


class Join extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.location.state.name,
            gameId: '',
            moveToLobby: false,
            unableToJoinActiveGame: false,
            otherJoinError : false
        };

        this.handleChange = this
            .handleChange
            .bind(this);
        this.joinGame = this
            .joinGame
            .bind(this);
    }

    handleChange(event) {
        let gameIdUppercase = event.target.value.toUpperCase();
        this.setState({gameId: gameIdUppercase});
    }

    async joinGame(){
        const loginResponse = await userMgr.loginToGame(this.state.name, this.state.gameId, false);
        console.log("[Join] Login response: " + loginResponse);
        if(loginResponse!= false && loginResponse != -1 && loginResponse != -2)
            this.setState({userFirebaseId: loginResponse, moveToLobby:true});
        else if(loginResponse == -1){
            this.setState({unableToJoinActiveGame : true});
        }else{
            this.setState({otherJoinError : true});
            console.log("[Join.js] Error logging user in");
        }
    }

    render() {
        if(this.state.moveToLobby){
            return <Redirect push to={{pathname:"/PreGameView",state:{name:this.state.name, gameId: this.state.gameId, host:false}}}/>
        }

        if(this.state.unableToJoinActiveGame){
            return <Redirect push to={{pathname:"/ErrorPage", state:{errorMessage : "Unable to join game because it is already in progress."}}}/>
        }

        if(this.state.otherJoinError){
            return <Redirect push to={{pathname:"/ErrorPage", state:{errorMessage : "Sorry, unable to join the requested game. Please check that the host sent the right link, and that they are currently waiting for players to join."}}}/>
        }

        return (
            <div>
                <h2>Hi {this.state.name}, enter the game code below:</h2>
                <form onSubmit={this.joinGame}>
                    <label>
                        Game Code:
                        <input type="text" value={this.state.gameId} onChange={this.handleChange}/>
                    </label>
                    <button type="submit"> Join Game </button>
                </form>
            </div>
        );
    }
}

export default Join;