import * as fbMgr from "./FirebaseMgr.js";
import * as gameGenerator from "./GameGenerator.js";
import * as userMgr from "./UserManager";
import * as cookieHandler from "./CookieHandler";
import {
    useImperativeHandle
} from "react";
import {
    write
} from "fs";


let userFirebaseId, allUsersRef, userRef, allGamesRef, currentGameRef;
let userKeys = [];
let playerCount = 0;
let startPos = null;
var gameRef = fbMgr.getGameRef();
let gameId;
let preGameViewRef;
let resultsViewRef;
let gameViewRef;
let currentRoundObj = {
    captionStr: "",
    image: "",
    isDrawing: false,
    isCaption: false,
    isWaiting: false,
    isFinished: false,
    currentSequence: null,
    roundNum: 1,
    currentResultSeq: null
};
let cachedGameObject = null;
let finalSequences;


export function registerHostStartListener(pgView, id) {
    preGameViewRef = pgView;

    // register state listener
    if (gameRef == null) {
        gameRef = fbMgr.getGameRef();
    }
    gameRef.child("gameData").child("state").on("value", function (snapshot) {
        if (snapshot.val() != "waitForPlayers") {
            console.log("[GameManager] game state = " + snapshot.val());

            // detach state listener
            gameRef.child("state").off();

            preGameViewRef.hostStarted();
        }
    })
}

export function onStartPosChange(newStartPos) {
    console.log("[GameManager] onStartPosChange, updating startpos to: " + newStartPos);
    currentRoundObj.currentSequence = newStartPos;
    if (cachedGameObject !== null) {
        onGameStateChange(cachedGameObject);
    } else {
        gameRef.child("gameData").once("value", function (snapshot) {
            onGameStateChange(snapshot);
        })
    }
}

export function getPlayerCount() {
    return playerCount;
}

export function getCurrentSeqNumber() {
    return currentRoundObj.currentResultSeq;
}

export function registerResultChangeListener(resultsView) {
    resultsViewRef = resultsView;

    if (userFirebaseId == null) {
        userFirebaseId = userMgr.getUserId();
    }


    // register state listener
    if (gameRef == null) {
        gameRef = fbMgr.getGameRef();
    }
    gameRef.child("gameData").child("currentResultSeq").on("value", function (snapshot) {
        let sequenceNum = snapshot.val();

        if (sequenceNum != -1) {
            console.log("[GameManager] currentResultSeq isn't -1, it's: " + sequenceNum);

            currentRoundObj.currentResultSeq = sequenceNum;

            getCurrentResultSequence().then(seq => {

                console.log("[GameManager] getCurrentResults sequence has resolved with data: " + JSON.stringify(seq));

                resultsViewRef.triggerResultsSequenceUpdate(seq);
            })
        }
    })
    gameRef.child("gameData").child("gameState").on("value", function (snapshot) {
        let state = snapshot.val();
        if (state == "resultsFinished") {
            console.log("[GameManager] state = resultsFinished, calling showResultsFinished");
            resultsViewRef.showResultsFinished();
        }
    })
}

export async function getCurrentResultSequence() {
    let _this = this;
    return new Promise(function (resolve, reject) {
        if (finalSequences == null) {
            let sequencesRef = gameRef.child("sequences");

            sequencesRef.once("value", function (data) {
                finalSequences = data.val();
                // remove null value at start of sequence
                finalSequences.shift();
                console.log("[GameManager] getCurrentResultSequence, finalSequences was null, now = " + JSON.stringify(finalSequences));

                let resultSequence = processCurrentResultsSequence();
                resolve(resultSequence);
            })

        } else {
            let resultSequence = processCurrentResultsSequence();
            resolve(resultSequence);
        }
    })
}

function processCurrentResultsSequence() {
    let resultSequence = finalSequences[currentRoundObj.currentResultSeq];

    console.log("[GameManager] originalPlayer = " + resultSequence.originalPlayer + " user = " + userFirebaseId);
    if (resultSequence.originalPlayer == userFirebaseId) {
        resultSequence.isOriginalPlayer = true;
    } else {
        resultSequence.isOriginalPlayer = false;
    }

    // TO DO: There are issues in this logic as it's set to false false on first and final sequences
    // if seq = 0
    if (currentRoundObj.currentResultSeq == 0) {
        resultSequence.isFirstSeq = true;
        resultSequence.isLastSeq = false;
    } else if (currentRoundObj.currentResultSeq >= playerCount - 1) {
        resultSequence.isFirstSeq = false;
        resultSequence.isLastSeq = true;
    } else {
        resultSequence.isFirstSeq = false;
        resultSequence.isLastSeq = false;
    }


    console.log("[GameManager] processCurrentResultsSequence, result sequence = " + JSON.stringify(resultSequence));

    return resultSequence;
}

export async function incrementResultSequence() {
    console.log("[GameManager] incrementResultSequence called")
    if (currentRoundObj.currentResultSeq < playerCount) {
        let tempSeqId = currentRoundObj.currentResultSeq + 1;
        gameRef.child("gameData").update({
            "currentResultSeq": tempSeqId
        });
    }
}

export async function decrementResultSequence() {
    console.log("[GameManager] decrementResultSequence called")
    if (currentRoundObj.currentResultSeq < playerCount) {
        let tempSeqId = currentRoundObj.currentResultSeq - 1;
        gameRef.child("gameData").update({
            "currentResultSeq": tempSeqId
        })
    }
}

export function finishGame() {
    console.log("[GameManager] finishGame called");
    let resultsUrl = "https://sillysketch.com?results=" + gameId;
    console.log("[GameManager] finishGame, resultsUrl: " + resultsUrl);
    return resultsUrl;
}

export function setResultsFinished() {
    gameRef.child("gameData").update({
        "gameState": "resultsFinished"
    })
}

export async function initialiseGameManager(isHost) {
    console.log("[GameManager] initialiseGameManager called");

    gameId = gameGenerator.getGameId();

    // register a listener for changes to game object
    if (gameRef == null) {
        gameRef = fbMgr.getGameRef();
    }
    gameRef.child("gameData").on("value", function (gameSnapshot) {
        console.log("[GameManager] Game snapshot update event")

        if (currentRoundObj.currentSequence == null) {
            userMgr.getStartPos().then((start) => {
                currentRoundObj.currentSequence = start;
                onGameStateChange(gameSnapshot);
            });
        } else {
            onGameStateChange(gameSnapshot);
        }

    });

    if (isHost) {
        userKeys = await userMgr.getUserKeys();
        console.log("[GameManager] initialising, userKeys = " + JSON.stringify(userKeys));

        if (userKeys.length != 0)
            requestGameInitialisation();

    } else {
        // get the current user's start position
        startPos = userMgr.getStartPos().then((start) => {
            startPos = start;

            // initialise the current sequence info
            determineCurrentSequence();
        });

    }
}

export function registerGameView(gv) {
    console.log("[GameManager] registerGameView called: " + gv);
    gameViewRef = gv;
}

export function setGameState(state) {
    gameRef.update({
        gameState: state
    });
}

export function setPrompt(prompt) {
    console.log("[GameManager] setPrompt: " + prompt + ", GV exists? " + gameViewRef);
    currentRoundObj.captionStr = prompt;
    if (gameViewRef) gameViewRef.onGameStateUpdate(currentRoundObj);
}

export function updatePromptInDb(prompt) {
    console.log("[GameManager] updatePromptInDb: " + prompt);
    currentRoundObj.captionStr = prompt;

    // get the current sequence and update its original prompt in the DB
    let seq = currentRoundObj.currentSequence;
    gameRef.child("sequences").child(seq).update({
        "0": prompt,
        "customPrompt": true
    });
}

export function setImage(url) {
    console.log("[GameManager] setImage: " + url + ", GV exists? " + gameViewRef);
    currentRoundObj.image = url;
    if (gameViewRef) gameViewRef.onGameStateUpdate(currentRoundObj);
}

const determineSequenceForImage = new Promise(function (resolve, reject) {

    let seq = currentRoundObj.currentSequence - 1;
    console.log("[GameManager] determineSequenceForImage called, seq before checking value: " + seq)
    // if after incrementing the sequence the sequence exceeds the player count (i.e. max num of seqeunces), then wrap around to the 1st sequence
    if (seq < 1) seq = playerCount;

    console.log("[GameManager] determineSequenceForImage called, final seq: " + seq);

    resolve(seq);
});


function requestGameInitialisation() {
    var initUrl = "https://us-central1-consequences-925ac.cloudfunctions.net/initialiseGame?gameId=" + gameId;

    let options = {
        mode: "cors",
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    }
    fetch(initUrl, options)
        .then(res => {
            console.log("initialise game fetch request status = " + res.status);
            return res.status
        })
        .then(status => {
            console.log("[GameManager] processing status code: " + JSON.stringify(status));
            if (status == 200) {
                console.log("[GameManager] requestGameInitialisation succeeded");

                // get the current user's start position
                startPos = userMgr.getStartPos().then((start) => {
                    startPos = start;

                    // initialise the current sequence info
                    determineCurrentSequence();

                    // Set game state to drawing
                    currentRoundObj.isDrawing = true;
                });

            } else {
                // add error handling
            }
        })
        .catch(error => console.log("[GameManager] requestGameInitialisation fetch failed: " + JSON.stringify(error)));
}


function onGameStateChange(gameSnapshot) {
    console.log("[GameManager] onGameStateChange " + JSON.stringify(gameSnapshot));


    // TODO: How to handle this race condition? Promise?
    if (currentRoundObj.currentSequence == null) {
        userMgr.getStartPos().then((start) => {
            currentRoundObj.currentSequence = start;
        });
    }

    cachedGameObject = JSON.parse(JSON.stringify(gameSnapshot)); // THIS IS THE LINE THAT NEEDS WORK!!!!
    console.log("[GameManager] onGameStateChange, cachedGameObject: " + cachedGameObject);

    // check the game state
    let gameState = gameSnapshot.val().state;
    playerCount = gameSnapshot.val().playerCount;
    let round = gameSnapshot.val().round;

    let sequencesRef = gameRef.child("sequences");

    sequencesRef.once("value").then(function (data) {
        let seqObj = data.val();

        console.log("[GameManager] sequencesRef = " + sequencesRef + " data = " + data + " seqObj = " + JSON.stringify(seqObj));

        if (seqObj != null) {
            // remove null value at start of sequence
            seqObj.shift();
            console.log("[GameManager] onGameStateChange, requested sequences; got: " + JSON.stringify(seqObj));
            return seqObj;
        }

    }).then(seqObj => {
        let shouldUpdateSeq = false;

        console.log("[GameManager] onGameStateChange current round = " + currentRoundObj.roundNum + " round = " + round);
        if (round > currentRoundObj.roundNum) {

            currentRoundObj.roundNum = round;

            if (startPos != null) {
                // reset user submitted state as this is a new round
                userMgr.setSubmissionState(false);

                let newSeq = determineCurrentSequence();
                if (newSeq != currentRoundObj.currentSequence) {
                    currentRoundObj.currentSequence = newSeq;
                    userMgr.setCurrentSeqNum(newSeq);

                    console.log("[GameManager] newSeq != currentSeq, so about to obtain round data, game state = " + gameState);

                    obtainRoundData(seqObj, currentRoundObj.currentSequence, currentRoundObj.roundNum - 1).then(data => {
                        if (gameState == "drawing") {
                            setPrompt(data);
                        } else if (gameState == "caption") {
                            setImage(data);
                        }
                    });


                }
                console.log("[GameManager] onGameStateChange updating current sequence: " + currentRoundObj.currentSequence);


            } else {
                userMgr.getStartPos().then((start) => {
                    startPos = start;
                    // reset user submitted state as this is a new round
                    userMgr.setSubmissionState(false);

                    let newSeq = determineCurrentSequence();

                    //////////////////////////////////////////////////////////////////////////////////////////////
                    // TO DO: Pull this out into a function so that it can also be used above
                    if (newSeq != currentRoundObj.currentSequence) {
                        currentRoundObj.currentSequence = newSeq;

                        console.log("[GameManager] newSeq != currentSeq, so about to obtain round data, game state = " + gameState);

                        // remove the first element in seqObj as it is always null
                        //seqObj.shift();

                        obtainRoundData(seqObj, currentRoundObj.currentSequence, currentRoundObj.roundNum - 1).then(data => {
                            if (gameState == "drawing") {
                                setPrompt(data);
                            } else if (gameState == "caption") {
                                currentRoundObj.image = data;
                            }
                        });


                    }

                    console.log("[GameManager] onGameStateChange updating current sequence after having got user's start pos: " + currentRoundObj.currentSequence);
                }).catch(error => {
                    console.log("[GameManager] error getting user's start pos during game update: " + error);
                });
            }

        }

    })

    if (gameState === "drawing") {
        currentRoundObj.isDrawing = true;
        currentRoundObj.isCaption = false;
        currentRoundObj.isWaiting = false;
        console.log("[GameManager] gameState is drawing, setting bools accordingly");
    } else if (gameState === "caption") {
        currentRoundObj.isDrawing = false;
        currentRoundObj.isCaption = true;
        currentRoundObj.isWaiting = false;
        console.log("[GameManager] gameState is caption, setting bools accordingly");
    } else if (gameState === "finished") {
        currentRoundObj.isDrawing = false;
        currentRoundObj.isCaption = false;
        currentRoundObj.isWaiting = false;
        currentRoundObj.isFinished = true;
        console.log("[GameManager] gameState is finished, setting bools accordingly");
    } else {
        currentRoundObj.isDrawing = false;
        currentRoundObj.isCaption = false;
        currentRoundObj.isWaiting = true;
        console.log("[GameManager] gameState is waiting, setting bools accordingly");
    }

    if (gameViewRef != null && gameViewRef != undefined) gameViewRef.onGameStateUpdate(currentRoundObj);


}

function determineCurrentSequence() {

    if (startPos === null) {
        userMgr.getStartPos().then((start) => {
            startPos = start;
        });
    }

    let tempSequence = currentRoundObj.currentSequence;

    console.log("[GameManager] determineCurrentSequence called, sequence before process: " + tempSequence)

    // If the current sequence has not yet been populated then initialise it to the player's start position
    if (tempSequence == null || tempSequence == undefined) {
        tempSequence = startPos;
    } else {
        tempSequence++;

        console.log("[GameManager] determineCurrentSequence else statement, seq after increment = " + tempSequence + ", playercount = " + playerCount);
        // if after incrementing the sequence the sequence exceeds the player count (i.e. max num of seqeunces), then wrap around to the 1st sequence
        if (tempSequence > playerCount) tempSequence = 1;
    }


    console.log("[GameManager] determineCurrentSequence finished, updated sequence: " + tempSequence);

    return tempSequence;
}

function getTargetField() {

    console.log("[GameManager] getTargetField called");

    // first specify which sequence object to look in
    let targetFieldName = "sequences/" + currentRoundObj.currentSequence + "/" + currentRoundObj.roundNum;
    console.log("[GameManager] Target field parent: " + targetFieldName);

    //targetFieldName.concat(currentRoundObj.roundNum);


    console.log("[GameManager] Target field complete: " + targetFieldName);
    return targetFieldName
}

export function writeData(data, name) {

    console.log("[GameManager] writeData called, data: " + data + " name: " + name);

    let targetField = getTargetField();

    gameRef.child(targetField).update({
        "data": data,
        "playerName": name
    })
}

function obtainRoundData(seqStructure, seq, round) {
    return new Promise(function (resolve, reject) {
        console.log("[GameManager] obtainRoundData called, struct: " + JSON.stringify(seqStructure) + ", seq: " + seq + ", round: " + round);
        //seqStructure = seqStructure[0];
        let sequence = seqStructure[seq - 1];
        let data = sequence[round].data;
        console.log("[GameManager] obtainRoundData finished, sequence: " + JSON.stringify(sequence) + ", data: " + data);
        resolve(data);
    })
}

export async function rejoin(gameToRejoin, previousUserId, rejoinLoadingContext) {
    console.log("[GameManager] rejoin invoked, gameToRejoin: " + gameToRejoin + " previousUserId: " + previousUserId);

    await userMgr.handleReconnect(previousUserId, gameToRejoin).then(reconnectResponse => {

        // If reconnect successful, init game mgr
        initialiseGameManager(false);
        
    }).then(()=>{
        userMgr.getName().then(playerName=>{
            return playerName;
        });
    }).then(playerName => {
        if (playerName !== false && playerName !== undefined) {
            rejoinLoadingContext.moveToGameView(playerName);
        } else {
            rejoinLoadingContext.moveToGameView("Unable to get player name after rejoin");
        }
    }).catch(error=>{
        console.error("[GameManager] error in rejoin: " + JSON.stringify(error));
    });




    // const canJoinGame = await userMgr.canJoinGame(this.state.gameId).catch(error=>{
    //     console.error("[GameManager] rejoin - Will not be able to attempt to rejoin game, error: " + error);
    //     if(error == -1){
    //         console.error("[GameManager] Game full, redirecting to full game error screen");
    //     }else{
    //         console.error("[JoinFromUrl] Game doesn't exist or is no longer active, redirecting to generic error screen");
    //     }

    // });
    // const loginResponse = await userMgr.loginToGame(this.state.name, gameToRejoin, false);
    // console.log("[GameManager] Rejoin - Login response: " + loginResponse);
    // if (loginResponse != false){
    //     console.log("[GameManager] successfully re-logged in")
    // }

    // else {
    //     console.error("[JoinFromUrl] Will not be able to attempt to join game, error: " + canJoinGame);
    //     this.setState({
    //         unableToJoinActiveGame: true
    //     });
    // }
}