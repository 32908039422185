import React, {Component, useState} from "react";
import {Route, NavLink, BrowserRouter, withRouter, Switch, Redirect} from "react-router-dom";
import Home from "./Home";
import PreGameView from "./PreGameView";
import Join from "./Join";
import GameView from "./GameView";
import About from "./About";
import JoinFromUrl from "./JoinFromUrl";
import ResultsFromUrl from "./ResultsFromUrl";
import PreviousGames from "./PreviousGames";
import Terms from "./Terms";
import ErrorPage from "./ErrorPage";
import Contact from "./Contact";
import DebugPage from "./DebugPage";
import RejoinLoading from "./RejoinLoading";
import FOF from "./404";
import * as cookieHandler from "./utils/CookieHandler";
import * as fbMgr from "./utils/FirebaseMgr";
import "./index.css";
import packageJson from '../package.json';
import Footer from "./components/Footer"
import CookieConsent from "react-cookie-consent";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Menu,
    MenuItem,
    Link
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
import TimerWidget from "./components/TimerWidget";
import DeviceDetector from "device-detector-js";
import { reject } from "q";

import RejoinPopup from "./components/RejoinPopup";


const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#aaaaf2',
            main: "#7979f3",
            dark: '#4f4ff7'
        },
        secondary: {
            main: '#f44336'
        }
    },
    typography: {
        useNextVariants: true
    }
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },

    title: {
        flexGrow: 1
    },
    appbar: {
      alignItems: 'center',
    }
}));

fbMgr.initialiseFirebaseMgr();

let prevGameIsActive = false;
let prevGameId = null;
let prevPlayerId = null;
let prevPlayerName = null;



let timerOnView = false;
let timerInitTime = null;
let timeElapsedCb = null;


const isSupportedBrowser = userAgent => {
    // if (userAgent.trim() === (this.state.userAgent || "").trim()) {
    //     return this.setState({userAgent});
    // }

    // let deviceDetector = new DeviceDetector();
    // const device = deviceDetector.parse(userAgent);
    // const browserName = device.client.name;

    // console.log("[Main] isSupportedBrowser, name: " + browserName + " is supported: " + !browserName.includes("book"));

    // if(browserName.includes("book")){
    //     return false;
    // }

    return true;
};

let hasCheckCookies = false;
let rejoinConfirmed = false;

function Main() {

    const [showRejoinPopup, setRejoinPopup] = useState(false);

    //console.log("[Main] window innerHeight: " + window.innerHeight + " + innerWidth: " + window.innerWidth + " outerHeight: " + window.outerHeight + " outerWidth: " + window.outerWidth);

    if(!hasCheckCookies){
        
        // check whether previous game is still active
        cookieHandler.cookieManagerInit().then((result)=>{
            cookieHandler.getCurrentGameFromCookie().then((gameObj)=>{
                console.log("[Main] cookie handling, current game: " + JSON.stringify(gameObj));
        
                prevGameId = gameObj.gameId;
                prevPlayerId = gameObj.playerId;
                prevPlayerName = gameObj.name;
        
                // Query whether the game is still active
                fbMgr.checkIfGameActive(prevGameId).then((isActive) => {
                    console.log("[Main] cookie handling, isGameActive: " + isActive);
                    
                    setRejoinPopup(isActive);
                })
        
            }).catch((error)=>{
                console.log("[Main] cookie handling - active game check, error: " + error);
            });
        })
    }

    hasCheckCookies = true;


    const classes = useStyles();
    const [anchorEl,
        open] = React.useState(null);
    const handleClick = event => {
        open(event.currentTarget);
    };

    const handleClose = () => {
        open(null);
    };

    const showTimer = function(time,cb){
        timerOnView = true;
        timerInitTime = time;
        timeElapsedCb = cb;
        console.log("[Main] showTimer, time: " + time);
    }
    
    
    const rejoin = (userConfirmed, gameId) => {
        setRejoinPopup(false);
        console.log("[Main] rejoin, userConfirmed: " + userConfirmed + " gameId: " + gameId);
        
        rejoinConfirmed = userConfirmed;
        if(rejoinConfirmed) fbMgr.logToFbAnalytics("rejoin-confirmed");
    }
    
    let allowAccess = isSupportedBrowser(navigator.userAgent);

    console.log("[Main] showRejoinPopup: " + showRejoinPopup);

    //showTimer(120, null)
    return (
    
        <MuiThemeProvider theme={theme}>

            <BrowserRouter>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={handleClick}>
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h4" className={classes.title}>
                          <Link href="/" variant="h4" color="inherit">Consequences</Link>
                        </Typography>
                    </Toolbar>
                    <div>
                        <Menu
                            id="Menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            <MenuItem onClick={handleClose}>
                            <NavLink to="/">Home</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <NavLink to="/About">About</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/PreviousGames">Previous Games</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <NavLink to="/Contact">Contact</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                            <NavLink to="/Terms">Privacy and Terms of Use</NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                            <NavLink to="/DebugPage">DEBUG</NavLink>
                            </MenuItem>

                        </Menu>
                    </div>
                </AppBar>
                <div>
                    <div className="content">
                        <Switch>
                        <Route exact path="/" render={(props) => <Home test={showTimer} {...props} />}/>
                        <Route path="/About" component={About}/>
                        <Route path="/PreGameView" component={PreGameView} props/>
                        <Route path="/Join" component={Join}/>
                        <Route path="/GameView" component={GameView}/>
                        <Route path="/JoinFromUrl" component={JoinFromUrl}/>
                        <Route path="/ResultsFromUrl" component={ResultsFromUrl}/>
                        <Route path="/Terms" component={Terms}/>
                        <Route path="/ErrorPage" component={ErrorPage}/>
                        <Route path="/PreviousGames" component={PreviousGames}/>
                        <Route path="/Contact" component={Contact}/>
                        <Route path="/DebugPage" component={DebugPage}/>
                        <Route path="/RejoinLoading" component={RejoinLoading}/>
                        <Route path="*" component={Home}/>
                        <Redirect to="/FOF" />
                        </Switch>
                    </div>
                {showRejoinPopup && <RejoinPopup open={true} rejoin={rejoin} gameId={prevGameId}/>}


                {rejoinConfirmed && <Redirect to={{
                    pathname : "/RejoinLoading",
                    state : { prevGameId, prevPlayerName, prevPlayerId}
                }}/>}


                {!allowAccess && <Redirect to={{
                    pathname : "/ErrorPage",
                    state : { errorMessage: "Please copy the link you were sent and open it in a specific browser app such as Chrome, Safari or Firefox.",
                secondaryMessage : "Unfortunately, Silly Sketch does not work in browsers such as Facebook popups."}
                    }}/>  }
                </div>
                <div>
                    <CookieConsent>This website uses cookies for anonymised analytics and to allow
                        users to revisit previous games. By continuing to use this site you accept the
                        usage of cookies, and the Terms of Usage.</CookieConsent>
                    <p>© 2020. Created by 
                        <a href="https://www.linkedin.com/in/ernarrowsmith/"> Ernie Arrowsmith</a>
                    </p>
                    <p>Email me at sillysketchgame@gmail.com</p>
                    <p>Beta build {packageJson.version}</p>
                </div>
            </BrowserRouter>
        </MuiThemeProvider>
    );
};

export default Main;