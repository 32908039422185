import React, {Component, useEffect , useState} from "react";
import {Redirect} from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function test() {}

let hasOpened = false;
let newPrompt = null;
let gameViewRef = null;

export default function NewPrompt(props) {

    //console.log(props);
    //gameViewRef = gv;

    const [open,
        setOpen] = React.useState(false);

    const handleClickOpen = () => {
        hasOpened = true;
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        newPrompt = null;
        props.close();
    };

    const handleChange = (event) => {
        //console.log("[NewPrompt] handleChange, event: " + event);
        // event.preventDefault();
        newPrompt = event.target.value;
        //console.log("[NewPrompt] handleChange, newPrompt: " + newPrompt);

    };

    const submitNewPrompt = (event) => {
        //event.preventDefault();
        console.log("[NewPrompt] submitNewPrompt called, event: " + event);
        console.log("props: " + props + " newPrompt" + newPrompt)
        if(props!==null && props !== undefined && newPrompt!== null && newPrompt !== undefined){
            console.log("[NewPrompt] submitNewPrompt, gameViewRef = " + gameViewRef);
            props.updatePrompt(newPrompt);
            setOpen(false);
            newPrompt = null;
            props.close();
        }
    }


    // this is a Hook API that's equivalent to componentDidMount, componentDidUpdate and componentDidUnmount
    useEffect(() => {
        handleClickOpen()
      });
    
    // // this is a Hook API that's equivalent to componentDidMount, componentDidUpdate and componentDidUnmount
    // useEffect(() => {
    //     // The cleanup function is effectively the same as unmount
    //     return function cleanup() {
    //         console.log("[NewPrompt] cleanup, setting newPrompt to null")
    //         if(!hasOpened) newPrompt = null;
    //         //hasOpened = false;
    //     };
    //   });
    
    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
     >
                <DialogTitle id="form-dialog-title">New Prompt</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your new prompt below.
                    </DialogContentText>
                    <TextField margin="normal" id="prompt" label="New Prompt"
                    multiline
                    rowsMax={4}
                    fullWidth value={newPrompt}  onBlur={handleChange}/>
                </DialogContent>
                
                <DialogActions>
                <Button onClick={submitNewPrompt} color="primary">
                    Submit
                </Button>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

// class NewPrompt extends Component {     constructor(props) { super(props);
//      this.state = {             newPrompt : "", gameViewRef :
// this.props.gameViewRef         };         this.handleChange = this
//  .handleChange             .bind(this); this.submitNewPrompt = this
//   .submitNewPrompt .bind(this);         //let gameViewRef =
// this.props.gameViewRef;     } handleChange(event) {
// event.preventDefault(); this.setState({newPrompt: event.target.value});     }
// submitNewPrompt(event){         event.preventDefault();
// console.log("[NewPrompt] submitNewPrompt, gameViewRef = "  +
// this.state.gameViewRef);
// this.state.gameViewRef.updatePrompt(this.state.newPrompt);     }     render()
// {         return (             <div>                 <form
// onSubmit={this.submitNewPrompt}>                     <label>        Please
// enter your new prompt below:                         <input type="text"
// value={this.state.newPrompt} onChange={this.handleChange}/>
// </label>                     <button type="submit"> Submit Prompt </button>
//               </form>             </div>         );     } } export default
// NewPrompt;