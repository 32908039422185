import * as firebaseMgr from "./FirebaseMgr.js";

var storage = firebaseMgr.storage;
let userFirebaseId, allUsersRef, userRef;

export function uploadImage(imageUrl,fileName,folderName) {
    return new Promise(function(resolve,reject){
        let storageRef = storage.ref();
        let file = storageRef.child(folderName + "/" + fileName);
    
        let dataStr = imageUrl.toString();
    
        console.log(dataStr)

    
        file.putString(dataStr,'data_url', {contentType:"image/png"}).then(function(snapshot){
            console.log("[FileUploader] upload success");

            file.getDownloadURL().then(url => {
                console.log("[FileUploader] success getting download URL: " + url);
                resolve(url);
            }).catch(error=>{
                console.error("[FileUploader] failure getting download URL: " + error);
                reject(false)
            })

        }).catch((error)=>{
            console.error("[FileUploader] Upload error: " + JSON.stringify(error));
            reject(error);
        })
    });
}