import React, {Component} from "react";
import { Redirect } from 'react-router';
import * as gameMgr from "./utils/GameManager";
import * as prevResultsHandler from "./utils/PreviousResultsHandler";
import * as cookieHandler from "./utils/CookieHandler";
import * as userMgr from "./utils/UserManager";
import CaptionResult from "./components/CaptionResult";
import DrawingResult from "./components/DrawingResult";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import CopyURLSnackbar from "./components/CopyURLSnackbar";
import * as clipboard from "clipboard-polyfill";
import ErrorSnackbar from "./components/ErrorSnackbar";
import CircularProgress from '@material-ui/core/CircularProgress';


const controlBarStyle = {
    position: "fixed"
}

class ResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt: "",
            data1 : "",
            name1 : "",
            data2 : "",
            name2 : "",
            data3 : "",
            name3 : "",
            data4 : "",
            name4 : "",
            data5 : "",
            name5 : "",
            data6 : "",
            name6 : "",
            data7 : "",
            name7 : "",
            data8 : "",
            name8 : "",
            data9 : "",
            name9 : "",
            data10 : "",
            name10 : "",
            playerCount : 0,
            isOriginalPlayer : false,
            isFirstSeq : true,
            isLastSeq : false,
            isHost : this.props.isHost,
            isCurrentGame : this.props.isCurrentGame,
            gameId : this.props.gameId,
            loaded : false,
            sequenceNum : 1,
            showCopyUrlMessage : false,
            cookieWritten : false,
            resultsFinished : false,
            errorMsg : null,
            showErrorSnackbar : false,
            showLoadingAnimation : true
        };

        this.processResultsSequence = this.processResultsSequence.bind(this);
        this.moveToNextSequence = this.moveToNextSequence.bind(this);
        this.triggerResultsSequenceUpdate = this.triggerResultsSequenceUpdate.bind(this);
        this.maxWidth = 500;
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.moveToPreviousSequence = this.moveToPreviousSequence.bind(this);
        this.topFunction = this.topFunction.bind(this);
        this.finishResults = this.finishResults.bind(this);
        this.resetToHome = this.resetToHome.bind(this);
        this.copyUrl = this.copyUrl.bind(this);
        this.showResultsFinished = this.showResultsFinished.bind(this);
        this.closePopup = this.closePopup.bind(this);
        
  
        // code to prompt user for confirmation on closing tab
        // window.addEventListener('beforeunload', function (e) { 
        //     if(this.state.isCurrentGame!==undefined && this.state.isCurrentGame!==null && this.state.isCurrentGame==true){
        //         e.preventDefault(); 
        //         e.returnValue = "Are you sure you want to close the game? You will not be able to rejoin.";
        //     }
        // }); 
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let iH,
            iW;
        if (window.innerWidth < 500) {
            iW = Math.round(window.innerWidth * 0.8);
        } else {
            iW = this.maxWidth;
        }
        iH = Math.round(iW * 1.3);
        console.log("[ResultsView] set dimensions: " + iH + " x " + iW)
        this.setState({imageWidth: iW, imageHeight: iH});
    }

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        if(this.state.isCurrentGame){
            this.setState({
                validGameCode : true
            })

            if(!this.state.cookieWritten){
                cookieHandler.updatePrevGames(this.state.gameId);
                this.setState({
                    cookieWritten : true
                })
            }

            let playerCount = gameMgr.getPlayerCount();
            this.setState({playerCount : playerCount});
            
            gameMgr.registerResultChangeListener(this);

            gameMgr.getCurrentResultSequence().then(seq => {
                this.processResultsSequence(seq);
            })

        }else{
            if(this.state.gameId !== null && this.state.gameId !== undefined){
                prevResultsHandler.retrievePreviousGame(this.state.gameId).then(prevGame => {
                    console.log("[ResultsView] previous game: " + JSON.stringify(prevGame));
                    
                    // set the player count (-2 because we don't want to include the "originalPlayer" or initial prompt)
                    let playerCount = Object.keys(prevGame).length-2;
                    console.log("[ResultsView] prev game, playerCount: " + playerCount);

                    this.setState({
                        playerCount : playerCount,
                        validGameCode : true
                    })

                    this.processResultsSequence(prevGame);
                })
                .catch(error =>{
                    console.error("[ResultsView] error getting previous game: " + error);
                    this.setState({
                        validGameCode : false,
                        loaded : true
                    })
                })
            }
        }

    }

    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }

    processResultsSequence(seq){
        console.log("[ResultsView] processResultsSequence, seq = " + JSON.stringify(seq));
        let prompt = seq[0];
        console.log("[ResultsView] prompt = " + prompt);
        this.setState({prompt : prompt});

        let stateObj = {};

        for(var i = 1; i<=this.state.playerCount; i++){
            let dataKey = "data" + i;
            let nameKey = "name" + i;

            let data = seq[i].data;
            let name = seq[i].playerName;

            stateObj[dataKey] = data;
            stateObj[nameKey] = name;
        }

        //let seqNum = this.state.sequenceNum + 1;

        stateObj.showLoadingAnimation = false;

        if(this.state.isCurrentGame){
            stateObj.isOriginalPlayer = seq.isOriginalPlayer;
            stateObj.isFirstSeq = seq.isFirstSeq;
            stateObj.isLastSeq = seq.isLastSeq;
            stateObj.sequenceNum = gameMgr.getCurrentSeqNumber()+1;
        }
        
        
        console.log("[ResultsView] setting state object: " + JSON.stringify(stateObj));

        this.setState(stateObj);

        this.topFunction();
    }

    triggerResultsSequenceUpdate(newSequence){
        console.log("[ResultsView] triggerResultsSequenceUpdate, newSequence = " + JSON.stringify(newSequence));
        this.processResultsSequence(newSequence);
    }

    moveToNextSequence(){
        console.log("[ResultsView] moveToNextSequence called, currentGame " + this.state.isCurrentGame + " sequenceNum = " + this.state.sequenceNum + " playerCount = " + this.state.playerCount);
        if(this.state.isCurrentGame) gameMgr.incrementResultSequence();
        else if(!this.state.isCurrentGame && this.state.sequenceNum < this.state.playerCount) {

            let newSequenceNum = this.state.sequenceNum +1;

            let isLast = prevResultsHandler.isLastSeq(newSequenceNum);
            console.log("[ResultsView] move to next sequence, isLast: " + isLast);
            this.setState({
                sequenceNum : newSequenceNum,
                isLastSeq : isLast,
                isFirstSeq : false
            })

            let nextSeq = prevResultsHandler.getSequence(newSequenceNum);
            this.processResultsSequence(nextSeq);
        }
        this.topFunction();
    }

    moveToPreviousSequence(){
        console.log("[ResultsView] moveToPreviousSequence called, currentGame " + this.state.isCurrentGame + " sequenceNum = " + this.state.sequenceNum + " playerCount = " + this.state.playerCount);
        if(this.state.isCurrentGame) gameMgr.decrementResultSequence();
        else if(!this.state.isCurrentGame && this.state.sequenceNum > 1) {

            let newSequenceNum = this.state.sequenceNum -1;

            let isFirst = (newSequenceNum === 1);

            this.setState({
                sequenceNum : newSequenceNum,
                isLastSeq : false,
                isFirstSeq : isFirst
            })

            let nextSeq = prevResultsHandler.getSequence(newSequenceNum);
            this.processResultsSequence(nextSeq);
        }
        this.topFunction();
    }

    finishResults(){
        console.log("[ResultsView] finishResults called")

        //let resultsUrl = gameMgr.finishGame();

        this.setState({
            resultsFinished : true
        });
        
        if(this.state.isCurrentGame) gameMgr.setResultsFinished();
    }

    showResultsFinished(){
        this.setState({
            resultsFinished : true
        });
    }

    resetToHome(){
        // clear history

        // navigate back to the home page
        window.removeEventListener('beforeunload', function (e) { 
            console.log("[ResultsView] removed events listener");
        }); 
        //window.location.reload();
        //window.location.href = "https://sillysketch.com";
        //window.location.href = "localhost:3000";
        this.props.endGame();
    }

    copyUrl(){
        let url = "https://sillysketch.com?results=" + this.state.gameId;
        let _this = this;
        clipboard.writeText(url).then(
            function() { 
                /* clipboard successfully set */
                _this.setState({
                    showCopyUrlMessage : true
                })
                console.log("[ResultsView] success copying to clipboard"); 
            },
            function() { 
                console.log("[ResultsView] error copying to clipboard"); 
                _this.setState({
                    showErrorSnackbar : true,
                    errorMsg : "Unable to copy to clipboard, please copy the URL manually."
                })
            }
          );
    }

    closePopup(){
        this.setState({
            showCopyUrlMessage : false,
            showErrorSnackbar : false,
            errorMsg : null
        })
    }

    render() {
        const {showLoadingAnimation, playerCount, isOriginalPlayer, isFirstSeq, isLastSeq, isHost, isCurrentGame, resultsFinished, prompt, validGameCode, loaded, showCopyUrlMessage, showErrorSnackbar} = this.state;

        return (
            <div>
                {showErrorSnackbar && <ErrorSnackbar errorMsg={this.state.errorMsg} close={this.closePopup}></ErrorSnackbar>}
                {/* <Button variant="outlined" color="primary"  onClick={() => this.copyUrl()}>Copy URL to Share!</Button> */}
                {!resultsFinished && <>
                {/* {!isCurrentGame && validGameCode && <Typography component="p" variant="p" align="center" color="textPrimary" gutterBottom>
                Viewing the results from game {this.state.gameId}
                </Typography>} */}
                {!isCurrentGame && !validGameCode && loaded && <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
                Sorry, unable to find anything for game code {this.state.gameId}  :(
                </Typography>}
                <br></br>

                {showLoadingAnimation && 
                <>
                    <br></br>
                    <Grid container justify="center" direction="column" alignItems="center" spacing={3}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                        <Grid item>
                            <Typography component="p" variant="p">Loading results...</Typography>
                        </Grid>
                    </Grid>
                </>}

                {!showLoadingAnimation && <>
                
                
                <Grid container spacing={1} direction="column" style={{position: 'fixed'}}>
                    <Paper elevation={0}>
                    <Grid container spacing={3} xs={12}>
                        <Grid item xs={4}>
                            {(isOriginalPlayer || !isCurrentGame || isHost) && !isFirstSeq && <Button variant="outlined" color="primary"  onClick={() => this.moveToPreviousSequence()}>Prev</Button>}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="h5" variant="h5" align="center">{this.state.sequenceNum} / {this.state.playerCount}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {(isOriginalPlayer || isHost || !isCurrentGame) && !isLastSeq && <Button variant="contained" color="primary"  onClick={() => this.moveToNextSequence()}>Next</Button>}
                            {(isOriginalPlayer || isHost) && isLastSeq && <Button variant="contained" color="primary"  onClick={() => this.finishResults()}>Finish Game</Button>}  
                        </Grid>
                    </Grid>
                    <Grid item xs={12} justify="center">
                        {!isOriginalPlayer && !isHost && isCurrentGame && <Typography component="p" variant="p"align="center">{this.state.name1} is in control of moving on!</Typography>}
                    </Grid>
                    </Paper>
                </Grid>
                
                
                <br></br>
                 
                {validGameCode && <>
                <br></br>
                <br></br>
                <br></br>
                <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                {this.state.name1} was originally asked to draw: <Typography component="h4" variant="h4" align="left" color="textPrimary">"{this.state.prompt}"</Typography>
                </Typography>
                
                <br></br>
                <DrawingResult name={this.state.name1} data={this.state.data1} width={this.state.imageWidth} height={this.state.imageHeight}></DrawingResult>
                <br></br>
                <CaptionResult name={this.state.name2} data={this.state.data2}></CaptionResult>
                <br></br>
                {playerCount>2 && <DrawingResult name={this.state.name3} data={this.state.data3} width={this.state.imageWidth} height={this.state.imageHeight}></DrawingResult>}
                <br></br>
                {playerCount>3 && <CaptionResult name={this.state.name4} data={this.state.data4}></CaptionResult>}
                <br></br>
                {playerCount>4 && <DrawingResult name={this.state.name5} data={this.state.data5} width={this.state.imageWidth} height={this.state.imageHeight}></DrawingResult>}
                <br></br>
                {playerCount>5 && <CaptionResult name={this.state.name6} data={this.state.data6}></CaptionResult>}
                <br></br>
                {playerCount>6 && <DrawingResult name={this.state.name7} data={this.state.data7} width={this.state.imageWidth} height={this.state.imageHeight}></DrawingResult>}
                <br></br>
                {playerCount>7 && <CaptionResult name={this.state.name8} data={this.state.data8}></CaptionResult>}
                <br></br>
                {playerCount>8 && <DrawingResult name={this.state.name9} data={this.state.data9} width={this.state.imageWidth} height={this.state.imageHeight}></DrawingResult>}
                <br></br>
                {playerCount>9 && <CaptionResult name={this.state.name10} data={this.state.data10}></CaptionResult>}
                <br></br>
                {/* <Grid container spacing={3}>
                    <Grid item xs={4}>
                        {(isOriginalPlayer || !isCurrentGame) && !isFirstSeq && <Button variant="outlined" color="primary"  onClick={() => this.moveToPreviousSequence()}>Previous sequence</Button>}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography component="h5" variant="h5">Sequence {this.state.sequenceNum} / {this.state.playerCount}</Typography>
                        {!isOriginalPlayer && isCurrentGame && <Typography component="p" variant="p"align="center">{this.state.name1} is in control of moving on!</Typography>}
                    </Grid>
                    <Grid item xs={4}>
                    {(isOriginalPlayer || !isCurrentGame) && !isLastSeq && <Button variant="contained" color="primary"  onClick={() => this.moveToNextSequence()}>Next sequence</Button>}
                    </Grid>
                </Grid>
                <br></br> */}
                </>}
                {isOriginalPlayer && isLastSeq && <Button variant="contained" color="primary"  onClick={() => this.finishResults()}>Finish Game</Button>}
            </>}

            </>}
            {resultsFinished && <>
                <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
                Thanks for playing!
                </Typography>
                <Typography component="p" variant="p" align="center" color="textPrimary" gutterBottom>
                If you want to view this game again in the future, you can do so by going to: https://sillysketch.com/?results={this.state.gameId}
                </Typography>
                <br></br>
                <Button variant="contained" color="primary"  onClick={() => this.resetToHome()}>Back to Home Page</Button>
                <Button variant="outlined" color="primary"  onClick={() => this.copyUrl()}>Copy URL to Share!</Button>
                
                <BottomNavigation
      
    >
      <BottomNavigationAction><Typography component="p" variant="p"></Typography></BottomNavigationAction>
    </BottomNavigation>
                </>
            }
            {showCopyUrlMessage && <CopyURLSnackbar close={this.closePopup}></CopyURLSnackbar>}
            </div>
        );
    }
}

export default ResultsView;