import React, {Component} from "react";
import {Typography, Button, Grid, Container} from "@material-ui/core";
import * as cookieHandler from "./utils/CookieHandler";
import PreviousGamesList from "./components/PreviousGamesList";

class PreviousGames extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gameList : null
        }

        this.getTimeStamp = this.getTimeStamp.bind(this);
        this.populateGamesList = this.populateGamesList.bind(this);

        this.populateGamesList();
    }

    getTimeStamp(id){
        /**
         * Game id structure is letters_epochTime, so we can split the id by the underscore then take the second half as the epoch time
         */
        let epochTime = id.split("_")[1];
        var date = new Date(epochTime * 1000); // multiply by 1000 for milliseconds
        var dateString = date.toLocaleString('en-GB');  // 24 hour format

        return dateString;
    }

    // populateGamesList(){
    //     cookieHandler.cookieManagerInit().then(res =>{
    //         cookieHandler.readPrevGames().then(res => {
    //             let prevGamesArr = res.split(",");

    //             //https://stackoverflow.com/a/46141923/3414962
    //             let listStr = "<ul>";

    //             this.setState({prevGames : prevGamesArr, gameCount: prevGamesArr.length});

    //             for(var i=prevGamesArr.length-1; i>=0; i--){
    //                 let timestamp = this.getTimeStamp(prevGamesArr[i]);
    //                 listStr += '<NavLink to="/?results=' + prevGamesArr[i] +'">'+ timestamp + '</NavLink><br/><br/>';
    //                 console.log("[PreviousGames] populateGamesList, building up listStr: " + listStr);
    //             }
    //             listStr+="</ul>";
    //             console.log("[PreviousGames] populateGamesList, finished listStr: " + listStr);
    //             this.setState({
    //                 gameList : listStr
    //             })
    //         })
    //     }).catch(error=>{
    //         console.error("[PreviousGames] error obtaining previous games: " + error);
    //     })
    // }

    populateGamesList(){
        cookieHandler.cookieManagerInit().then(res =>{
            cookieHandler.readPrevGames().then(res => {
                let prevGamesArr = res.split(",");

                //https://stackoverflow.com/a/46141923/3414962
                let listStr = "<ul>";

                this.setState({prevGames : prevGamesArr, gameCount: prevGamesArr.length});

                let gameList = [];
                for(var i=prevGamesArr.length-1; i>=0; i--){

                    // we need to get the previous games into an array of objects [{gameId : timestamp}]
                    let timestamp = this.getTimeStamp(prevGamesArr[i]);
                    let gameId = prevGamesArr[i];
                    let tempObj = {};
                    tempObj[gameId] = timestamp;
                    gameList.push(tempObj);

                    console.log("[PreviousGames] populateGamesList, building up gameList: " + JSON.stringify(gameList));
                }
                listStr+="</ul>";
                console.log("[PreviousGames] populateGamesList, finished gameList: " + JSON.stringify(gameList));
                this.setState({
                    gameList : gameList
                })
            })
        }).catch(error=>{
            console.error("[PreviousGames] error obtaining previous games: " + error);
        })
    }

    render() {
        const {gameList} = this.state;
        return (
            <div>
                <Container maxWidth="sm">
                <Typography component="h4" variant="h4" align="center" gutterBottom>Previous Games</Typography>
                {gameList!==null && <>
                    <Typography component="p" variant="p" align="center">Click on any of the games below to look at the results!</Typography>
                    <PreviousGamesList gameList={this.state.gameList}></PreviousGamesList></>
                }
                {gameList==null && <>
                    <Typography component="p" variant="p" align="center">No previous games found. Go and play some :D</Typography>
                    </>
                }
                </Container>

                
            </div>
        );
    }
}

export default PreviousGames;