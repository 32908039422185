import React, {Component} from "react";
import DeviceDetector from "device-detector-js";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CopyURLSnackbar from "./components/CopyURLSnackbar";

class DebugPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userAgent: undefined,
            executionTime: undefined,
            device: undefined,
            showCopyMessage: false
        }

        this.parseUserAgent = this
            .parseUserAgent
            .bind(this);
        this.copyInfo = this
            .copyInfo
            .bind(this);
        this.closePopup = this
            .closePopup
            .bind(this);
        this.deviceDetector = new DeviceDetector();

        this.startup = this
            .startup
            .bind(this);
        this.handleCancel = this
            .handleCancel
            .bind(this);
        this.handleEnd = this
            .handleEnd
            .bind(this);
        this.handleMove = this
            .handleMove
            .bind(this);
        this.handleStart = this
            .handleStart
            .bind(this);
        this.copyTouch = this
            .copyTouch
            .bind(this);
        this.colorForTouch = this
            .colorForTouch
            .bind(this);
        this.ongoingTouches = [];
        this.drawLog = "";
    }

    componentDidMount() {

        if (window.location.hash) {
            const userAgent = decodeURIComponent(window.location.hash.substr(1));

            return this.parseUserAgent(userAgent);
        }

        this.parseUserAgent(navigator.userAgent);

        document.addEventListener("DOMContentLoaded", this.startup);

        // dummy listeners to enable touch to work on iOS
        window.addEventListener("touchstart", {});
        window.addEventListener("touchcancel", {});
        window.addEventListener("touchend", {});
        window.addEventListener("touchmove", {});
    }

    parseUserAgent = userAgent => {
        if (userAgent.trim() === (this.state.userAgent || "").trim()) {
            return this.setState({userAgent});
        }

        const device = this
            .deviceDetector
            .parse(userAgent);

        const model = device.device.model;
        const browser = device.client.name + " ver " + device.client.version;
        const browserName = device.client.name;
        const browserVersion = device.client.version;
        const engine = device.client.engine + " ver " + device.client.engineVersion;
        const engineVersion = device.client.engineVersion;
        const osName = device.os.name;
        const osVersion = device.os.version;
        const client = device.client;
        const os = device.os.name + " ver " + device.os.version;

        this.log(JSON.stringify(client));
        this.log(JSON.stringify(os));
        //this.log(JSON.stringify(dev));

        this.setState({userAgent, device, model, browser, engine, os});
    };

    copyInfo() {
        let _this = this;
        let clipboardText = {
            "deviceInfo": this.state.device,
            "drawLog" : this.drawLog
        }
        navigator
            .clipboard
            .writeText(JSON.stringify(clipboardText))
            .then(() => {
                /* clipboard successfully set */
                _this.setState({showCopyMessage: true})
            }, () => {
                /* clipboard write failed */
            });
    }

    closePopup() {
        this.setState({showCopyMessage: false})
    }

    startup() {
        var el = document.getElementById("canvas");
        el.addEventListener("touchstart", this.handleStart, false);
        el.addEventListener("touchend", this.handleEnd, false);
        el.addEventListener("touchcancel", this.handleCancel, false);
        el.addEventListener("touchmove", this.handleMove, false);
    }

    handleStart(evt) {
        evt.preventDefault();
        console.log("[DebugPage] handleStart, event: " + JSON.stringify(evt));
        this.log("touchstart.");
        var el = document.getElementById("canvas");
        var ctx = el.getContext("2d");
        var touches = evt.changedTouches;

        for (var i = 0; i < touches.length; i++) {
            this.log("touchstart:" + i + "...");
            this
                .ongoingTouches
                .push(this.copyTouch(touches[i]));
            var color = this.colorForTouch(touches[i]);
            ctx.beginPath();
            ctx.arc(touches[i].pageX, touches[i].pageY, 4, 0, 2 * Math.PI, false); // a circle at the start
            ctx.fillStyle = color;
            ctx.fill();
            this.log("touchstart:" + i + ".");
        }
    }
    handleMove(evt) {
        evt.preventDefault();
        console.log("[DebugPage] handleMove, event: " + JSON.stringify(evt));
        var el = document.getElementById("canvas");
        var ctx = el.getContext("2d");
        var touches = evt.changedTouches;

        for (var i = 0; i < touches.length; i++) {
            var color = this.colorForTouch(touches[i]);
            var idx = this.ongoingTouchIndexById(touches[i].identifier);

            if (idx >= 0) {
                this.log("continuing touch " + idx);
                ctx.beginPath();
                this.log("ctx.moveTo(" + this.ongoingTouches[idx].pageX + ", " + this.ongoingTouches[idx].pageY + ");");
                ctx.moveTo(this.ongoingTouches[idx].pageX, this.ongoingTouches[idx].pageY);
                this.log("ctx.lineTo(" + touches[i].pageX + ", " + touches[i].pageY + ");");
                ctx.lineTo(touches[i].pageX, touches[i].pageY);
                ctx.lineWidth = 4;
                ctx.strokeStyle = color;
                ctx.stroke();

                this
                    .ongoingTouches
                    .splice(idx, 1, this.copyTouch(touches[i])); // swap in the new touch record
                this.log(".");
            } else {
                this.log("can't figure out which touch to continue");
            }
        }
    }
    handleEnd(evt) {
        evt.preventDefault();
        this.log("touchend");
        var el = document.getElementById("canvas");
        var ctx = el.getContext("2d");
        var touches = evt.changedTouches;

        for (var i = 0; i < touches.length; i++) {
            var color = this.colorForTouch(touches[i]);
            var idx = this.ongoingTouchIndexById(touches[i].identifier);

            if (idx >= 0) {
                ctx.lineWidth = 4;
                ctx.fillStyle = color;
                ctx.beginPath();
                ctx.moveTo(this.ongoingTouches[idx].pageX, this.ongoingTouches[idx].pageY);
                ctx.lineTo(touches[i].pageX, touches[i].pageY);
                ctx.fillRect(touches[i].pageX - 4, touches[i].pageY - 4, 8, 8); // and a square at the end
                this
                    .ongoingTouches
                    .splice(idx, 1); // remove it; we're done
            } else {
                this.log("can't figure out which touch to end");
            }
        }
    }
    handleCancel(evt) {
        evt.preventDefault();
        this.log("touchcancel.");
        var touches = evt.changedTouches;

        for (var i = 0; i < touches.length; i++) {
            var idx = this.ongoingTouchIndexById(touches[i].identifier);
            this
                .ongoingTouches
                .splice(idx, 1); // remove it; we're done
        }
    }

    colorForTouch(touch) {
        var r = touch.identifier % 16;
        var g = Math.floor(touch.identifier / 3) % 16;
        var b = Math.floor(touch.identifier / 7) % 16;
        r = r.toString(16); // make it a hex digit
        g = g.toString(16); // make it a hex digit
        b = b.toString(16); // make it a hex digit
        var color = "#" + r + g + b;
        this.log("color for touch with identifier " + touch.identifier + " = " + color);
        return color;
    }

    copyTouch({identifier, pageX, pageY}) {
        return {identifier, pageX, pageY};
    }
    
    ongoingTouchIndexById(idToFind) {
        for (var i = 0; i < this.ongoingTouches.length; i++) {
            var id = this.ongoingTouches[i].identifier;

            if (id == idToFind) {
                return i;
            }
        }
        return -1; // not found
    }

    log(msg) {
        //var p = document.getElementById('log');
        //p.innerHTML = msg + "\n" + p.innerHTML;
        this.drawLog = this.drawLog + "\n" + msg;
    }

    render() {
        const {showCopyMessage} = this.state;
        return (
            <div>
                <div style={{display: "flex"}}>
                    <Typography component="p" variant="p" gutterBottom >Model: {JSON.stringify(this.state.model)}</Typography>
                    <br></br>
                </div>
                <div style={{display: "flex"}}>
                    <Typography component="p" variant="p" gutterBottom >Browser: {JSON.stringify(this.state.browser)}</Typography>
                    <br></br>
                </div>
                <div style={{display: "flex"}}>
                    <Typography component="p" variant="p" gutterBottom >Engine: {JSON.stringify(this.state.engine)}</Typography>
                    <br></br>
                </div>
                <div style={{display: "flex"}}>
                    <Typography component="p" variant="p" gutterBottom >OS: {JSON.stringify(this.state.os)}</Typography>
                </div>
                <Button variant="outlined" color="primary" onClick={() => this.copyInfo()}>Copy useful information to clipboard</Button>

                {showCopyMessage && <CopyURLSnackbar close={this.closePopup}></CopyURLSnackbar>}
                <br></br>
                <canvas id="canvas" width="300" height="300" style={{border: "1px solid black"}}
                >
                </canvas>
                <br></br>
                    {/*<Typography component="h5" variant="h5">Log:</Typography>
                    <pre id="log" style={{border: "1px solid black"}}></pre>*/}

                </div>
                )
                }
}

export default DebugPage;