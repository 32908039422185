import React, {Component} from "react";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

class DrawingResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : this.props.data,
            name : this.props.name,
            imageWidth : this.props.width,
            imageHeight : this.props.height
        };

        console.log("[DrawingResult] init, w: " + this.state.imageWidth + " h: " + this.state.imageHeight);
    }

    componentDidUpdate(prevProps){
        if(prevProps.data !== this.props.data || prevProps.name !== this.props.name){
            this.setState({data : this.props.data,  name : this.props.name});
        }
    }

    render() {
        return (
            <div>
                <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>{this.state.name} drew this:</Typography>
                <br></br>
                <img src={this.state.data} width={this.state.imageWidth} height={this.state.imageHeight}></img>
            </div>
        );
    }
}

export default DrawingResult;