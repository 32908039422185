import * as fbMgr from "./FirebaseMgr.js";
import * as gameGenerator from "./GameGenerator.js";
import * as gameMgr from "./GameManager.js";
import * as cookieHandler from "./CookieHandler";

var database = fbMgr.database;
let userFirebaseId,
    allUsersRef,
    userRef,
    allGamesRef,
    currentGameRef;
let playerList = [];
let userKeys = [];
let currentSequence;
let startPos = null;
let previousSubmissionState = null;
let promptNotAlreadyProvided = true;
let notifiedOfStartPos = false;

export function getUserId() {
    return userFirebaseId;
}

export async function getUserKeys() {
    return userKeys;
}

export async function getPlayerNames() {
    console.log("[UserManager] getPlayerNames = " + JSON.stringify(playerList));
    return playerList;
}

/**
 * 
 * @param {*} returnRef 
 * @param {*} onlyDisconnect true = only process and notify of any disconnects
 */
export function registerUserListChangeListener(returnRef, onlyDisconnect) {

    console.log("[UserManager] registerUserListChangeListener, return: " + returnRef + " onlyDisconnect: " + onlyDisconnect);

    if (allUsersRef == null) {
        return false;
    }

    /*var gameId = gameGenerator.getGameId();
    var ref = fbMgr.getMasterRef();
    var gameRef = ref.child(gameId);
    allUsersRef = gameRef.child("users");
    console.log(JSON.stringify(ref))
    console.log(JSON.stringify(gameRef))*/
    console.log(JSON.stringify(allUsersRef))

    allUsersRef.on("value", function (usersSnapshot) {
        console.log("[UserManager] allUsersRef update")

        let usersArr = [];

        // if a new player has joined
        usersSnapshot.forEach(function (childSnapshot) {

            usersArr.push(childSnapshot.name);
            // check whether this user is new, if so, store their key and value
            var key = childSnapshot.key;
            if (!userKeys.includes(key) && !onlyDisconnect) {
                console.log("[UserManager] New user!");
                userKeys.push(childSnapshot.key);
                playerList.push(childSnapshot.val().name);
                //let name = playerList[key].name;
                returnRef.updatePlayerList(playerList);
            }else if(childSnapshot.val().stillInGame === false){
                console.log("[UserManager] user disconnected: " + childSnapshot.val().name);
                returnRef.playerDisconnected(childSnapshot.val().name);
            }
        });

        if(!onlyDisconnect) returnRef.updatePlayerList(playerList);
    });

    // register listener for the specific user

    console.log("user listener registered successfully")
    return true;

}

export function setSubmissionState(state) {
    console.log("[UserManager] setSubmissionState: " + state, " previousSubmissionState: " + previousSubmissionState);

    if (state != previousSubmissionState) {
        // userRef.update({     "submitted": state,     "serverShouldProcess" : true });
        let userPath = "users/" + userFirebaseId + "/submitted";
        let updateData = {
            "serverShouldProcess": true
        };
        updateData[userPath] = state;
        console.log(updateData)
        currentGameRef.update(updateData)
    }

    previousSubmissionState = state;
}

export function setCurrentSeqNum(seqNum){
    console.log("[UserManager] setCurrentSeqNum: " + seqNum);

    let updateData = {
        "currentSequence": seqNum
    };
    console.log("[UserManager] setCurrentSeqNum, updateData: " + JSON.stringify(updateData));
    currentGameRef.child("users").child(userFirebaseId).update(updateData)

}

/* Trying to stop people joining mid game
export async function loginToGame(playerName, gameId, isHost) {

    return new Promise(function (resolve, reject) {
        console.log("[UserManager] loginToGame called, isHost: " + isHost);

        playerList.push(playerName);

        if (!isHost) {
            // need to get a reference to this game first
            gameGenerator.joinGame(gameId).then(joinRes =>{
                currentGameRef = joinRes;
                allUsersRef = currentGameRef.child("users");
                if(allUsersRef !== null){
                    resolve(allUsersRef);
                }else{
                    reject(-2);
                }
                
            });
        } else {
            currentGameRef = fbMgr.getGameRef();
            allUsersRef = fbMgr.getUsersRef();
            if(allUsersRef !== null){
                resolve(allUsersRef);
            }else{
                reject(-2);
            }
        }
    }).then(allUsersRef => {
        if (allUsersRef !== null && allUsersRef !== undefined) {

            console.log("[UserManager] allUsersRef!=null");

            // check if game is already in progress
            currentGameRef.child("gameData").child("state").once("value").then(function (data) {
                let gameState = data.val();
                console.log("[UserManager] loginToGame, current game state: " + JSON.stringify(gameState));
                if (gameState == "waitForPlayers") {
                    // push a new unique user into the user object and store the unique ID of this user
                    userFirebaseId = allUsersRef.push({
                        name: playerName,
                        currentSequence: "",
                        sequences: {},
                        isHost: isHost,
                        stillInGame: true
                    }).getKey();

                    userKeys.push(userFirebaseId);

                    return userFirebaseId;
                }
            }).catch(error => {
                console.error("[UserManager] loginToGame, get gamestate promise failed: " + error);
                return -1;
            });
        } else {
            // return error to user
            return -2;
        }
    }).catch(error => {
        console.error("[UserManager] loginToGame, promise failed: " + error);
        return -2;
    })       

}*/

// REVERTED TO THIS FROM V0.8.3
export async function loginToGame(playerName, gameId, isHost) {

    console.log("[UserManager] loginToGame called, isHost: " + isHost);

    playerList.push(playerName);

    if (!isHost) {
        // need to get a reference to this game first
        currentGameRef = await gameGenerator.joinGame(gameId);
        allUsersRef = currentGameRef.child("users");
    } else {
        currentGameRef = fbMgr.getGameRef();
        allUsersRef = fbMgr.getUsersRef();
    }

    if (allUsersRef != null) {

        console.log("[UserManager] allUsersRef!=null");

        // push a new unique user into the user object and store the unique ID of this user
        userFirebaseId = allUsersRef.push({
            name: playerName,
            currentSequence: "",
            sequences: {},
            isHost: isHost,
            stillInGame: true
        }).getKey();

        userKeys.push(userFirebaseId);
        cookieHandler.storeCurrentGame(gameId, userFirebaseId, playerName);
        return userFirebaseId;

    } else {
        // return error to user
        return "Error logging in";
    }
}


export async function canJoinGame(gameId){
    console.log("[UserManager] canJoinGame invoked, gameId: " + gameId)
    return new Promise(function(resolve, reject){
        let gameRef = fbMgr.getGameRef(gameId);
        gameRef.child("users").once("value", function (snapshot) {

            // count how many user entries there are in this game
            let playerCount = Object.keys(snapshot.val()).length;
            console.log("[UserManager] canJoinGame, playerCount: " + playerCount);

            if(playerCount<10) resolve(true);
            else reject(-1);
        }).catch(error=>{
            console.error("[UserManager] canJoinGame, error: " + error);
            reject(-2);
        })
    })
}

export function registerUserListener() {
    console.log("[UserManager] registerUserListener, userFirebaseId : " + userFirebaseId + " allUsersRef = " + JSON.stringify(allUsersRef));
    // set a listener for this user
    userRef = allUsersRef.child(userFirebaseId);
    userRef.on("value", function (userSnapshot) {
        userStateChanged(userSnapshot.val());
    })

    // register disconnect handler
    userRef.onDisconnect().update({
        "stillInGame" : false
    });

}

export function getStartPos() {
    return new Promise(function (resolve, reject) {

        //if(userRef==null) reject();

        console.log("[UserManager] getStartPos called");

        userRef.once("value", function (data) {
            console.log("[UserManager] getStartPos data read: " + JSON.stringify(data));
            startPos = data
                .val()
                .startPos;
            console.log("[UserManager] getStartPos returning startpos: " + startPos);

            if (startPos !== null && startPos !== undefined)
                resolve(startPos);
            else {
                registerStartPosListener();
                reject();
            }
        });

    });
}

export function registerStartPosListener() {
    console.log("[UserManager] registerStartPosListener called");
    userRef
        .child("startPos")
        .on("value", function (data) {

            console.log("[UserManager] startPosChange: " + JSON.stringify(data));
            startPos = data.val();
            if (startPos !== null && !notifiedOfStartPos) {

                notifiedOfStartPos = true;
                console.log("[UserManager] startPosChange pos not null, so removing listener");

                userRef
                    .child("startPos")
                    .off("value");

                console.log("[UserManager] startPosChange calling gameMgr.onStartPosChange: " + startPos);

                gameMgr.onStartPosChange(startPos);
            }
        });
}

async function setupDbRefs(gameId) {}

function userStateChanged(userSnapshot) {
    console.log(userSnapshot);
    currentSequence = userSnapshot.currentSequence;
    if (userSnapshot.prompt && promptNotAlreadyProvided) {
        console.log("[UserManager] userStateChanged - calling set prompt")
        gameMgr.setPrompt(userSnapshot.prompt);
        promptNotAlreadyProvided = false;
    }

    // console.log("[UserManager] userStateChanged Current sequence = " +
    // currentSequence);
}


export function handleDisconnect(fbRef){
    if(userFirebaseId!==null & userFirebaseId!==undefined && userRef!==null && userRef!==undefined){
        // set the current user as not in game
        userRef.child("stillInGame").onDisconnect().update(false);
    }
}

export async function handleReconnect(userId, gameToRejoin){

    console.log("[UserManager] handleReconnect invoked, userId: " + userId + " gameId: " + gameToRejoin);
    userFirebaseId = userId;

    // Get game reference
    currentGameRef = await gameGenerator.joinGame(gameToRejoin);
    allUsersRef = currentGameRef.child("users");
    
    // Register user listener
    registerUserListener();

    // Check the data obtained by userStateChanged


    // Set still in game = true
    userRef.update({
        "stillInGame" : true
    })

    // Return success or failure
}

export async function getName(){
    console.log("[UserManager] getName invoked");

    userRef.once("value", function(snapshot){
        let name = snapshot.val().name;
        console.log("[UserManager] getName success: " + name);
        return name;
    }).catch(error => {
        console.error("[UserManager] getName error: " + JSON.stringify(error));
        return false;
    })
}