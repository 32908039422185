// let resultsCookie = null;
// let currentGameCookie = null;

// /**
//  * This function stores the game ID and the date so that people can then retrieve 
//  */
// export function updateResultCookie(gameId) {

//     let date = new Date();
//     const options = {
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric'
//     };
//     let dateStr = date.toLocaleDateString(options);
//     let newResult = JSON.stringify({
//         "date": dateStr,
//         "gameId": gameId
//     });
//     if(resultsCookie!=null) resultsCookie.push(newResult);
//     else resultsCookie = [newResult];
//     document.cookie =
//         "results=" + JSON.stringify(resultsCookie) + "; expires=Wed, 1 Jan 2031 00:00:00 UTC; path=/";
// }

// export function processCookies() {

//     // Convert cookie string to obj (https://stackoverflow.com/a/50452780)
//     let cookies = document.cookie.split('; ').reduce((prev, current) => {
//         const [name, value] = current.split('=');
//         prev[name] = value;
//         return prev
//       }, {});

//     // check whether there is a cookie for a game in progress
//     if(cookies.currentGameId){
//         // check whether game is still active
//     }

//     // check whether there is a cookie for results
//     if (cookies.results) {
//         // store the existing results object
//         resultsCookie = cookies.results;
//     }
// }

// export function resetGameIdCookie(){
//     document.cookie = "currentGameId"
// }

let cookieObj;

/*
 * Read cookies and extract to an object
 */
function cookieToObj() {
    console.log("[CookieHandler] cookieToObj invoked");
    return new Promise(function(resolve, reject){
        let cookies = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, {
                    [key]: JSON.parse(val)
                })
            } catch (e) {
                return Object.assign(res, {
                    [key]: val
                })
            }
        }, {});
        console.log("[CookieHandler] cookieToObj finished, returning: " + JSON.stringify(cookies));
        resolve(cookies);
    })
    
}

/*
 * Update results list cookie
 */
 export function updatePrevGames(gameId){
     console.log("CookieHandler] updatePrevGames called, gameId: " + gameId + " cookieObj.prev: " + JSON.stringify(cookieObj.prev));
    if(cookieObj.prev !== null && cookieObj.prev !== undefined){
        console.log("CookieHandler] updatePrevGames, prev not null");
        let prevGameArr = cookieObj.prev.split(",");
        prevGameArr.push(gameId);
        document.cookie = "prev=" + prevGameArr + "; expires=Wed, 1 Jan 2031 00:00:00 UTC;";
    }else if(cookieObj !== null && cookieObj !== undefined && cookieObj.prev == null){
        console.log("CookieHandler] updatePrevGames, prev IS null");
        document.cookie = "prev=" + [gameId] + "; expires=Wed, 1 Jan 2031 00:00:00 UTC;";
    }
 }

 export function readPrevGames(){
     let _this = this;
     return new Promise(function(resolve, reject){
        if(cookieObj!==null && cookieObj!==undefined && cookieObj.prev!==null && cookieObj.prev!==undefined){
            resolve(cookieObj.prev);
        }else{
            reject("No previous games");
        }
     });
 }

 //export function updatePrevGames(gameId){
 //}

 export function storeCurrentGame(gameId, playerId, name){
     console.log("[CookieHandler] storeCurrentGame, gameId: " + gameId + " playerId: " + playerId, " name: " + name);
     document.cookie = "gameId=" + gameId;
     document.cookie = "playerId=" + playerId;
     document.cookie = "name=" + name;
 }

export function getCurrentGameFromCookie(){
    let _this = this;
    return new Promise(function(resolve, reject){
        if(cookieObj!==null && cookieObj!==undefined && cookieObj.gameId!==null && cookieObj.gameId!==undefined && cookieObj.playerId !== undefined && cookieObj.playerId !== null ){
            // If the cookie contains a gameId and playerId, retun them as an object
            let gameObj = {
                "gameId" : cookieObj.gameId,
                "playerId" : cookieObj.playerId
            }
            resolve(gameObj);
        }else{
            reject(false);
        }
    });
}

 export function resetCurrentGame(){
     document.cookie = "gameId=null";
     document.cookie = "playerId=null";
 }

export function cookieManagerInit(){
    console.log("[CookieHandler] cookieManagerInit invoked");
    return new Promise(function(resolve, reject){
        // get the current cookies
        cookieToObj().then(cookies => {
            console.log("[CookieHandler] cookieManagerInit cookieToObj resolved, cookieObj: " + JSON.stringify(cookies));
            cookieObj = cookies;
            resolve(true);
        }).catch(error=>{
            console.error("[CookieHandler] cookieManagerInit error: " + JSON.stringify(error));
            reject(false);
        })
    })
    
}