const colours = {
    primary: '#fff',
    primaryContrast: '#000',
    secondary: '#51b3d1',
    secondaryContrast: '#fff',
    midGrey: '#7d7d7d',
    darkGrey: '#5c5c5c',
    lightGrey: '#eaeaea',
    background: '#fff',
    backgroundContrast: '#000',
    footerBg: "#86ff36",
    canvas: '#ececec',
  };
  
  export default colours;