import React, {Component} from "react";
import {Redirect} from 'react-router';
import CanvasDraw from "react-canvas-draw";
import * as fileUploader from './utils/FileUploader';
import GameHeader from "./components/GameHeader";
import {Button, Typography, Container, Grid, Box, AppBar, Toolbar} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ColourMenu from "./components/ColourMenu";


class DrawingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            captionStr: this.props.captionStr,
            gameId: this.props.gameId,
            sequence: this.props.sequence,
            sequenceStage: this.props.round,
            canvasHeight: 0,
            canvasWidth: 0,
            submit: this.props.submitted,
            colour: "#000000",
            brushRadius: 5,
            gameViewRef : this.props.gameViewRef,
            isFirstRound : this.props.isFirstRound
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.submitDrawing = this.submitDrawing.bind(this);
        this.changeColour = this.changeColour.bind(this);

        this.showPromptEntry = this.showPromptEntry.bind(this);

        this.getCanvasToExport = this.getCanvasToExport.bind(this);

        this.undo = this.undo.bind(this);

        this.maxWidth = 500;

        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        this.updateWindowDimensions();
        console.log("[DrawingView] mounted, caption: " + this.state.captionStr + " game id = " + this.state.gameId);
        //window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentDidUpdate(prevProps){
        if(prevProps.captionStr !== this.props.captionStr){
            this.setState({captionStr : this.props.captionStr});
        }
        if(prevProps.isFirstRound !== this.props.isFirstRound){
            this.setState({isFirstRound : this.props.isFirstRound});
        }
    }

    componentWillUnmount() {
        this.setState({captionStr : ""});
        console.log("[DrawingView] unmounted, reset caption to: " + this.state.captionStr);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        console.log("[DrawingView] updateWindowDimensions called; height = " + window.outerHeight + "width = " + window.outerWidth);
        let cH,
            cW;
        if (window.outerWidth < 500) {
            cW = Math.round(window.outerWidth * 0.83);
        } else {
            cW = this.maxWidth;
        }
        cH = Math.round(cW * 1.3);
        console.log("[DrawingView] updateWindowDimensions, calculated new height and width: " + cH + " x " + cW)
        this.setState({canvasWidth: cW, canvasHeight: cH});
        window.scrollTo(0,1)
    }

    showPromptEntry(){
        this.state.gameViewRef.showPromptEntry();
    }

    changeColour(btn) {
        switch (btn) {
            case "red":
                this.setState({colour: "#ff0000", brushRadius: 4});
                break;
            case "blue":
                this.setState({colour: "#0400ff", brushRadius: 4});
                break;
            case "black":
                this.setState({colour: "#000000", brushRadius: 4});
                break;
            case "yellow":
                this.setState({colour: "#f5f242", brushRadius: 4});
                break;
            case "green":
                this.setState({colour: "#44fc30", brushRadius: 4});
                break;
            case "orange":
                this.setState({colour: "#ff6200", brushRadius: 4});
                break;
            case "pink":
                this.setState({colour: "#ff85ca", brushRadius: 4});
                break;
            case "brown":
                this.setState({colour: "#7d3800", brushRadius: 4})
                break;
            case "grey":
                this.setState({colour: "#c5c6c7", brushRadius: 4})
                break;
            case "white":
                this.setState({colour: "#ffffff", brushRadius: 4});
                break;
            case "grey":
                this.setState({colour: "#dbdbdb", brushRadius: 4});
                break;
            case "purple":
                this.setState({colour: "#bf37ed", brushRadius: 4});
                break;
            case "eraser":
                this.setState({colour: "#ffffff", brushRadius: 8});
                break;
        }
    }

    getCanvasToExport(){
        return 
    }

    submitDrawing(){
        let _this = this;

        return new Promise(function(resolve, reject){

            //let canvasToExport = this.canvasRef.current.canvas.drawing;
            let canvasToExport = _this.canvasRef.current.canvas.drawing;
        let context = canvasToExport.getContext("2d")
        //cache height and width		
        let w = canvasToExport.width;
        let h = canvasToExport.height;
        let data;		
    
        //get the current ImageData for the canvas.
        data = context.getImageData(0, 0, w, h);

        //store the current globalCompositeOperation
        var compositeOperation = context.globalCompositeOperation;

        //set to draw behind current content
        context.globalCompositeOperation = "destination-over";

        //set background color
        context.fillStyle = "#ffffff";

        //draw background / rect on entire canvas
        context.fillRect(0,0,w,h);
    
        var imageData = canvasToExport.toDataURL(`image/png`).toString();
    
        //clear the canvas
        context.clearRect (0,0,w,h);

        //restore it with original / cached ImageData
        context.putImageData(data, 0,0);		

        //reset the globalCompositeOperation to what it was
        context.globalCompositeOperation = compositeOperation;
        console.log(imageData)  


        var folder = _this.state.gameId + "/" + _this.state.sequence;
        var filename = _this.state.sequenceStage;
        console.log("[DrawingView] requesting image upload: " + folder + "/" + filename);
        fileUploader.uploadImage(imageData, filename, folder).then(uploadResult => {
            console.log("[DrawingView] upload result: " + uploadResult);
            //return the Base64 encoded data url string
            if (uploadResult !== false) {
                // let fullPath = folder + "/" + filename;
                console.log("[DrawingView] returning URL: " + uploadResult);
                resolve(uploadResult);
            } else {
                console.error("[DrawingView] uploadImage error: " + uploadResult);
                reject(false);
            }
        })
        .catch(error => {
            console.error("[DrawingView] uploadImage error (catch statement): " + error);
            reject(false);
        });
        })
        

        
      }

      undo(){
        console.log("[DrawingView] undo pressed");
        this.canvasRef.current.undo();
      }

    
    render() {
        
        if(this.state.submit){
            this.submitDrawing();
        }

        const {isFirstRound} = this.state;

        return (
            <div>

                <Grid   justify="center" container  direction="column" alignItems="center" spacing={2}>
                {/* <Grid item xs={12} container justify="center">
                    <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
                        Draw "{this.state.captionStr}"
                    </Typography>
                </Grid>
                <br></br>
                {isFirstRound && <>
                    <Grid item xs={12} container justify="center">
                    <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
                    or
                    </Typography>
                    </Grid>
                    <br></br>
                    <Grid item xs={12} container justify="center">
                        <Container maxWidth="sm">
                    <Button variant="contained" color="primary" fullWidth="true"  onClick={()=>this.showPromptEntry()}>Set your own prompt</Button>
                    </Container>
                    </Grid>
                    </>} */}



                <Grid item xs={12}>
                    <GameHeader time={120} 
                        isFirstRound={this.state.isFirstRound} 
                        caption={this.state.captionStr}
                        timeElapsedCb={this.state.gameViewRef.timeElapsed}
                        storeTimeCb={this.state.gameViewRef.storeTime}
                        editPromptCb={this.showPromptEntry}
                        roundInProgress={true}
                        isCaption={false}></GameHeader>
                    
                </Grid>

                <div></div>
                <Grid item  justify="center">
                    <CanvasDraw style={{border:"thick ridge black"}} lazyRadius={1} hideInterface={true} requireMovement={false} ref={this.canvasRef} hideGrid={true} brushRadius = {this.state.brushRadius}  brushColor={this.state.colour} canvasWidth={this.state.canvasWidth} canvasHeight={this.state.canvasHeight}/>
                </Grid>

                <Grid container xs={12} justify="center">
                <Grid item xs={8} justify="center">
                    {/* <Box flexWrap="wrap">
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("black")}>Black</Button> 
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("blue")}>Blue</Button> 
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("red")}>Red</Button>  
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("yellow")}>Yellow</Button> 
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("orange")}>Orange</Button>                 
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("pink")}>Pink</Button>  
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("green")}>Green</Button>  
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("brown")}>Brown</Button> 
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("grey")}>Grey</Button>
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("white")}>White</Button>
                    <Button variant="outlined" size="medium" onClick={()=>this.changeColour("eraser")}>Eraser</Button>
                <br></br>
                <br></br>
                </Box> */}
                    <ColourMenu changeColour={this.changeColour}></ColourMenu>
                </Grid>
                <Grid item xs={4} justify="center">
                <Button variant="outlined" color="primary" fullWidth="true" onClick={()=>this.undo()}>Undo!</Button>
                </Grid>
                </Grid>
                
                <br></br>
                </Grid>
            </div>
        );
    }
}

export default DrawingView;
