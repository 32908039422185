import React, {Component} from "react";
import ResultsView from "./ResultsView";
 

class ResultsFromUrl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gameId : this.props.location.state.gameId
        }
    }


    render() {
        return (
            <ResultsView gameId={this.state.gameId} isCurrentGame={false}></ResultsView>
        );
    }
}

export default ResultsFromUrl;