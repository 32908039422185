// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app");

// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/firestore");
require("firebase/database");
require("firebase/analytics");
require("firebase/storage");


const firebaseConfig = {
    apiKey: "AIzaSyB8tJjaqsO6ePS71LSameR7EpIZpjz7HGU",
    authDomain: "consequences-925ac.firebaseapp.com",
    databaseURL: "https://consequences-925ac.firebaseio.com",
    projectId: "consequences-925ac",
    storageBucket: "consequences-925ac.appspot.com",
    messagingSenderId: "63943140796",
    appId: "1:63943140796:web:e493ee05acf444026337b9",
    measurementId: "G-7SZ6LCEV4H"
  };

let gameRef, usersRef;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
let db = firebase.database();
let ref = db.ref("games");




export function initialiseFirebaseMgr(){
    console.log("[FirebaseManager] initialiseFirebase called");
    // authenticate app
    firebase.auth().signInAnonymously().then(()=>{
        console.log("[FirebaseManager] auth success")
        ref = db.ref("games");
    }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error("[FirebaseManager] auth error " + errorCode + " " + errorMessage)
    });
    
    //firebase.analytics().logEvent("initialiseFirebaseCalled");
}

export function logToFbAnalytics(event, data){
    if(data!=null && data!=undefined) firebase.analytics().logEvent(event,data);
    else  firebase.analytics().logEvent(event);
}

export function getGameRef(gameId){
    console.log("[FirebaseManager] game ref requested for gameId: " + gameId);
    if(gameRef==null && gameId!==null && gameId!==undefined){
        gameRef = ref.child(gameId);
        return gameRef;
    }else{
        return gameRef;
    }
}

export function getPrevGameRef(id){
    return new Promise(function(resolve,reject){
        console.log("[FirebaseManager] getPrevGameRef, id: " + id);
        if(ref==null || ref==undefined){
            console.log("[FirebaseManager] getPrevGameRef - main ref null");
            ref = db.ref("games");
            console.log("[FirebaseManager] getPrevGameRef - main ref now: " + ref);
        }

        let prevGameRef = ref.child(id);

        console.log("[FirebaseManager] getPrevGameRef: " + JSON.stringify(prevGameRef));

        if(prevGameRef != null && prevGameRef != undefined) resolve(prevGameRef);
        else reject("Unable to obtain prevGameRef");
    })
}

export function storeRefs(game, users){
    gameRef = game;
    usersRef = users;
    console.log("[FirebaseManager] stored gameRef: " + JSON.stringify(gameRef));
    console.log("[FirebaseManager] stored usersRef: " + JSON.stringify(usersRef));
}

export function getUsersRef(){
    console.log("[FirebaseManager] usersRef requested: " + JSON.stringify(gameRef));
    return usersRef;
}

export function getMasterRef(){
    if(ref!=null) return ref;
}

export function storeGameRef(ref){
    gameRef = ref;
}

export function checkIfGameActive(gameId){
    let _this = this;
    return new Promise(function(resolve, reject){
        if(ref !== undefined && ref !== null ){
            
            ref.child(gameId).child("gameData").once("value",function (snapshot) {
                if(snapshot.val()!==null)
                {
                    console.log("[FirebaseManager] checkIfGameActive, gameData: " + JSON.stringify(snapshot.val().state));
                    if(snapshot.val().state==="finished") reject(false);
                    else resolve(true);
                }
                else{
                    reject(false);
                }

            }).catch((error)=>{
                console.log("[FirebaseManager] checkIfGameActive, error: " + error);
                reject(false);
            })
        }else{
            reject(false);
        }
    });
}

//exports.database = firebase.database();
export var database = firebase.database();
export var storage = firebase.storage();