import React, {Component} from "react";
import {Redirect} from 'react-router';
import * as gameMgr from "./utils/GameManager";
import GameHeader from "./components/GameHeader";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

class CaptionView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caption: '',
            name: this.props.name,
            gameViewRef :  this.props.gameViewRef,
            prompt : this.props.prompt
        };

        this.getCaption = this
            .getCaption
            .bind(this);

        this.handleChange = this
            .handleChange
            .bind(this);

        this.submitCaption = this
            .submitCaption
            .bind(this);

        this.updateWindowDimensions = this
            .updateWindowDimensions
            .bind(this);

        this.maxWidth = 500;
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let iH,
            iW;
        if (window.innerWidth < 500) {
            iW = Math.round(window.innerWidth * 0.8);
        } else {
            iW = this.maxWidth;
        }
        iH = Math.round(iW * 1.3);
        console.log("[CaptionView] set dimensions: " + iH + " x " + iW)
        this.setState({imageWidth: iW, imageHeight: iH});
    }

    handleChange(event) {
        this.setState({caption: event.target.value});
    }

    getCaption() {
        let _this = this;
        console.log("[CaptionView] getCaption called");

        return new Promise(function (resolve, reject) {

            if (_this.state.caption != null & _this.state.caption != undefined) {
                
                console.log("[CaptionView] returning caption: " + _this.state.caption);

                if(_this.state.caption==="") resolve("No caption submitted, draw whatever you want!");
                resolve(_this.state.caption);
            } else {
                console.error("[CaptionView] getCaption error, caption string = " + _this.state.caption);
                reject(false);
            }
        })
        // .catch(error => {     console.error("[CaptionView] getCaption error (catch
        // statement): " + error);     reject(false); });
    }

    submitCaption(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div>

                <GameHeader time={60} 
                    isFirstRound={false}
                    isCaption={true}
                    timeElapsedCb={this.state.gameViewRef.timeElapsed}
                    storeTimeCb={this.state.gameViewRef.storeTime}
                    roundInProgress={true}></GameHeader>
                {/* <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    gutterBottom>
                    What do you think this is?
                </Typography> */}
                
                <Container maxWidth="sm">
                <img
                    src={this.props.imageUrl}
                    width={this.state.imageWidth}
                    height={this.state.imageHeight}></img>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <form noValidate onSubmit={this.submitCaption}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="caption"
                                    label="Caption"
                                    name="caption"
                                    multiline
                                    rowsMax={1}
                                    value={this.state.caption}
                                    onChange={this.handleChange}/>
                            </form>
                        </Grid>

                    </Grid>
                </Container>

            </div>
        );
    }
}

export default CaptionView;